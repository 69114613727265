import { BlobItem, BlobServiceClient } from '@azure/storage-blob';
import { useCallback } from 'react';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { listCollection } from '../../services/listCollection/listCollection';

const useListCollection = (): Function => {
  const { STORAGE_ACCOUNT_NAME } = useEnvContext();
  return useCallback(
    async (
      sasToken: string,
      prefix: string,
      abortControllerSignal: AbortSignal
    ): Promise<AsyncIterableIterator<BlobItem>> => {
      try {
        const accountName = STORAGE_ACCOUNT_NAME;
        const containerName = 'beesnftstorage';

        const blobService = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        return listCollection(containerClient, prefix, abortControllerSignal);
      } catch (error) {
        /* istanbul ignore next */
        console.error(error);
      }
    },
    []
  );
};

export { useListCollection };
