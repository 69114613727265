import React, { createContext, ReactNode, useCallback, useContext, useReducer } from 'react';
import { ICampaignForm } from '../../../models/campaignFormModel';
import { ICampaignInfoAction, initialState } from './campaignInfo.actions';
import { campaignInfoReducer } from './campaignInfo.reducer';
import { ICampaignInfoContext } from './campaignInfo.types';

interface ICampaignInfoContextProps {
  children: ReactNode;
}

export const CampaignInfoContext = createContext<ICampaignInfoContext>(initialState);

const CampaignInfoContextProvider = ({ children }: ICampaignInfoContextProps) => {
  const [state, dispatch] = useReducer(campaignInfoReducer, initialState);

  const setCampaignInfoRequest = useCallback(() => {
    dispatch({
      type: ICampaignInfoAction.CAMPAIGN_INFO_REQUEST,
      payload: null,
    });
  }, []);

  const setCampaignInfoSuccess = useCallback((campaignInfoData: ICampaignForm) => {
    dispatch({
      type: ICampaignInfoAction.CAMPAIGN_INFO_SUCCESS,
      payload: campaignInfoData,
    });
  }, []);

  const setCampaignInfoError = useCallback((error: number) => {
    dispatch({
      type: ICampaignInfoAction.CAMPAIGN_INFO_ERROR,
      payload: error,
    });
  }, []);

  const setIsCampaignFormValid = useCallback((isValid: boolean) => {
    dispatch({
      type: ICampaignInfoAction.CAMPAIGN_FORM_VALIDATION,
      payload: isValid,
    });
  }, []);

  const setIsUploading = useCallback((isUploading: boolean) => {
    dispatch({
      type: ICampaignInfoAction.CAMPAIGN_INFO_UPLOADING,
      payload: isUploading,
    });
  }, []);

  const campaignInfoValue = {
    campaignInfo: state.campaignInfo,
    isLoading: state.isLoading,
    campaignInfoError: state.campaignInfoError,
    isCampaignFormValid: state.isCampaignFormValid,
    isUploading: state.isUploading,
    setCampaignInfoRequest,
    setCampaignInfoSuccess,
    setCampaignInfoError,
    setIsCampaignFormValid,
    setIsUploading,
  };

  return (
    <CampaignInfoContext.Provider value={campaignInfoValue}>
      {children}
    </CampaignInfoContext.Provider>
  );
};
export const useCampaignInfoContext = () => {
  return useContext(CampaignInfoContext);
};
export default React.memo(CampaignInfoContextProvider);
