import { BlobDownloadResponseParsed, BlobItem } from '@azure/storage-blob';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useCompressCollection } from '../useCompressCollection/useCompressCollection';
import { useDownloadCollection } from '../useDownloadCollection/useDownloadCollection';
import { useGetSasToken } from '../useGetSasToken/useGetSasToken';
import { StorageOperations } from '../useGetSasToken/useGetSasToken.types';
import { useListCollection } from '../useListCollection/useListCollection';
import { AzureStorage } from '../useUploadCollection/useUploadCollection';

interface IHookProps {
  azureStorage: AzureStorage;
  campaignId: string;
  reveal: boolean;
  setIsDownloading: Dispatch<SetStateAction<boolean>>;
}
type Hook = () => ({
  azureStorage,
  campaignId,
  reveal,
  setIsDownloading,
}: IHookProps) => Promise<void>;

const useAzureDownloadManagement: Hook = () => {
  const getSasToken = useGetSasToken();
  const listCollection = useListCollection();
  const compressCollection = useCompressCollection();
  const downloadCollection = useDownloadCollection();
  const toastService = useToast();
  const { formatMessage } = useIntl();

  return useCallback(
    async ({ azureStorage, campaignId, reveal, setIsDownloading }: IHookProps) => {
      setIsDownloading(true);

      try {
        const listSasToken: string = await getSasToken(StorageOperations.LIST);
        const collectionItems: AsyncIterableIterator<BlobItem> = await listCollection(
          listSasToken,
          `${campaignId}/compressed-${campaignId}-${azureStorage}.zip`
        );

        const blobItem = await collectionItems.next();

        if (!blobItem.value) await compressCollection(reveal);

        const downloadSasToken: string = await getSasToken(StorageOperations.DOWNLOAD);
        const collectionCompressedBlob: BlobDownloadResponseParsed = await downloadCollection(
          downloadSasToken,
          reveal
        );
        const compressedBlob = await collectionCompressedBlob.blobBody;
        const collectionCompressedFile = new File(
          [compressedBlob],
          `${campaignId}-${azureStorage}.zip`,
          {
            type: 'application/zip',
          }
        );

        const compressedCollectionUrl = window.URL.createObjectURL(collectionCompressedFile);
        window.open(compressedCollectionUrl, 'download');

        setIsDownloading(false);
      } catch (error) {
        setIsDownloading(false);
        toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({
            id: 'campaignFormAggregator.collectionForm.toast.downloadFailed',
          }),
        });
      }
    },
    [getSasToken, listCollection, toastService]
  );
};

export { useAzureDownloadManagement };
