import { Alert, Button, Paragraph } from '@hexa-ui/components';
import { ReactElement } from 'react';

import { ButtonsWrapper, ConfirmationWrapper } from '../ContentCard.styles';

interface ConfirmationProps {
  props: {
    alert?: string;
    description?: any;
    element?: ReactElement;
    primaryButton: string;
    secondaryButton: string;
  };
  confirmationAction: () => void;
  setConfirmationStep: (bool: boolean) => void;
}

const Confirmation = ({ props, setConfirmationStep, confirmationAction }: ConfirmationProps) => {
  const handleConfirmed = async () => {
    setConfirmationStep(false);
    await confirmationAction();
  };

  const handleNotConfirmed = () => {
    setConfirmationStep(false);
  };

  return (
    <ConfirmationWrapper data-testid="confirmation">
      {props.alert && (
        <Alert message={props.alert} type="warning" className="alert" data-testid="alert-text" />
      )}

      {props.description && <Paragraph className="longer">{props.description}</Paragraph>}

      {props.element || null}

      <ButtonsWrapper>
        <Button
          size="medium"
          variant="secondary"
          onClick={() => handleNotConfirmed()}
          data-testid="primary-button"
        >
          {props.primaryButton}
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={() => handleConfirmed()}
          data-testid="secondary-button"
        >
          {props.secondaryButton}
        </Button>
      </ButtonsWrapper>
    </ConfirmationWrapper>
  );
};

export default Confirmation;
