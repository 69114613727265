import CreatedCard from './CardsByStatus/CreatedCard/CreatedCard';
import DoneCard from './CardsByStatus/DoneCard/DoneCard';
import OnGoingCard from './CardsByStatus/OnGoingCard/OnGoingCard';
import { AirdropCardProps } from './types';

const AirdropCard: React.FC<AirdropCardProps> = ({
  id,
  status,
  nftsAvailable,
  nftsHolders,
  snapshotDate,
  blockNumber,
  minted,
  sentOn,
  leadTime,
  setIsDeleting,
}) => {
  return (
    <>
      {status === 'CREATED' && (
        <CreatedCard
          id={id}
          nftsAvailable={nftsAvailable}
          blockNumber={blockNumber}
          nftsHolders={nftsHolders}
          snapshotDate={snapshotDate}
          setIsDeleting={setIsDeleting}
        />
      )}

      {status === 'ONGOING' && (
        <OnGoingCard
          total={nftsHolders}
          progress={minted}
          blockNumber={blockNumber}
          snapshotDate={snapshotDate}
        />
      )}

      {status === 'DONE' && (
        <DoneCard
          snapshotDate={snapshotDate}
          blockNumber={blockNumber}
          sentOn={sentOn}
          leadTime={leadTime}
          minted={minted}
        />
      )}
    </>
  );
};

export default AirdropCard;
