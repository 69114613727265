import { BlobItem, BlobServiceClient } from '@azure/storage-blob';
import { useCallback, useContext } from 'react';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { deleteCollection } from '../../services/deleteCollection/deleteCollection';

const useDeleteCollection = (): Function => {
  const { campaignInfo } = useContext(CampaignInfoContext);
  const { STORAGE_ACCOUNT_NAME } = useEnvContext();

  return useCallback(
    async (
      sasToken: string,
      blobItem: BlobItem,
      abortControllerSignal?: AbortSignal
    ): Promise<void> => {
      try {
        const accountName = STORAGE_ACCOUNT_NAME;
        const containerName = 'beesnftstorage';

        const blobService = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        const blockBlobClient = containerClient.getBlockBlobClient(blobItem.name);

        await deleteCollection(blockBlobClient, abortControllerSignal);
      } catch (error) {
        console.error(error);
      }
    },
    [campaignInfo]
  );
};

export { useDeleteCollection };
