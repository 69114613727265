import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { SetupStepIcon } from './SetupStepIcon';

import { CustomStepperProps } from './types';

const CustomStepper: React.FC<CustomStepperProps> = ({
  activeStep,
  stepsData,
  handleStepClick,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stepper orientation="vertical" activeStep={activeStep} data-testid="campaignStepper">
      {stepsData.map((step, index) => (
        <Step
          key={step.label}
          data-active={`${index === activeStep ? 'active' : 'unactive'}`}
          data-completed={`${index >= activeStep ? 'uncompleted' : 'completed'}`}
        >
          <StepLabel
            onClick={() => handleStepClick(index)}
            StepIconComponent={(props) => SetupStepIcon({ ...props, step: index + 1 })}
            data-testid={`step-${index}`}
          >
            {formatMessage({
              id: step.label,
            })}
          </StepLabel>
          <StepContent>
            {formatMessage({
              id: step.description,
            })}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
