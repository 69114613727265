import { BlobDownloadResponseParsed, BlobServiceClient } from '@azure/storage-blob';
import { useCallback, useContext } from 'react';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { downloadCollection } from '../../services/downloadCollection/downloadCollection';

const useDownloadCollection = (): Function => {
  const { STORAGE_ACCOUNT_NAME } = useEnvContext();
  const { campaignInfo } = useContext(CampaignInfoContext);

  return useCallback(
    async (sasToken: string, reveal: boolean): Promise<BlobDownloadResponseParsed> => {
      try {
        const accountName = STORAGE_ACCOUNT_NAME;
        const containerName = 'beesnftstorage';

        const blobService = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        const blockBlobClient = containerClient.getBlockBlobClient(
          `${campaignInfo.id}/compressed-${campaignInfo.id}-${
            reveal ? 'placeholder' : 'collection'
          }.zip`
        );

        return await downloadCollection(blockBlobClient);
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    []
  );
};

export { useDownloadCollection };
