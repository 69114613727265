import { ICampaignForm } from '../../../models/campaignFormModel';
import { ICampaignInfoContext } from './campaignInfo.types';

export const campaignInfoInitialState: ICampaignForm = {
  id: '',
  launchDate: '',
  brand: '',
  campaign: '',
  campaignDescription: '',
  campaignSymbol: '',
  externalUrl: '',
  nftBasePrice: '',
  status: 'Draft',
  lateReveal: false,
  reveal: {
    revealed: false,
  },
  airdrop: [],
  preSale: {
    enable: false,
    startDate: '',
    endDate: '',
    basePrice: '',
    allowList: [],
    uploadDate: '',
  },
  minting: {
    blockChain: 'Ethereum',
    postMinting: true,
    gasFeeCap: 0,
    smartGas: true,
    allowList: [],
    uploadDate: '',
  },
  liveCollectionStorage: {
    uploaded: false,
    baseUri: '',
  },
  placeholderCollectionStorage: {
    uploaded: false,
    baseUri: '',
  },
  preSaleAllowListFile: [],
  collectionFile: [],
  mintingAllowListFile: [],
  inputErrors: {
    brand: '',
    campaign: '',
    externalUrl: '',
    campaignSymbol: '',
    nftBasePrice: '',
    gasFeeCap: '',
    airdropAddresses: '',
    airdropNftsHoldersContract: '',
    airdropNftsHoldersSnapshot: '',
  },
};

export const initialState: ICampaignInfoContext = {
  isLoading: true,
  campaignInfo: campaignInfoInitialState,
  campaignInfoError: null,
  isCampaignFormValid: false,
  isUploading: false,
  setCampaignInfoRequest: () => undefined,
  setCampaignInfoSuccess: () => undefined,
  setCampaignInfoError: () => undefined,
  setIsCampaignFormValid: () => undefined,
  setIsUploading: () => undefined,
};

export enum ICampaignInfoAction {
  CAMPAIGN_INFO_REQUEST = 'CAMPAIGN_INFO_REQUEST',
  CAMPAIGN_INFO_SUCCESS = 'CAMPAIGN_INFO_SUCCESS',
  CAMPAIGN_INFO_ERROR = 'CAMPAIGN_INFO_ERROR',
  CAMPAIGN_FORM_VALIDATION = 'CAMPAIGN_FORM_VALIDATION',
  CAMPAIGN_INFO_UPLOADING = 'CAMPAIGN_INFO_UPLOADING',
}
