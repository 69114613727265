import axios from 'axios';
import { IHolders } from '../../../../models/holdersModel';

export interface IGetHoldersProps {
  campaignId: string;
  vendor: string;
  title: string;
  date?: string;
}

const getHoldersService = async (
  baseUrl: string,
  bearerToken: string,
  contractAddress: string,
  params: IGetHoldersProps
): Promise<any> => {
  return axios.get<IHolders>(`${baseUrl}nft/holders/${contractAddress}`, {
    params: {
      campaignId: params.campaignId,
      vendor: params.vendor,
      campaignTitle: params.title,
      date: params.date,
    },
    headers: { authorization: bearerToken },
  });
};

export { getHoldersService };
