import { useIntl } from 'react-intl';

export const confirmationConfig = (isTested: boolean) => {
  const { formatMessage } = useIntl();

  return {
    data: {
      alert: isTested
        ? formatMessage({
            id: 'campaignDashboardPage.contentCard.publishContract.confirmation.alert',
          })
        : formatMessage({
            id: 'campaignDashboardPage.contentCard.publishContract.confirmation.alertWithoutTest',
          }),
      description: (
        <span>
          {formatMessage({
            id: 'campaignDashboardPage.contentCard.publishContract.confirmation.description',
          })}
        </span>
      ),
      primaryButton: formatMessage({
        id: 'campaignDashboardPage.contentCard.publishContract.confirmation.primaryButton',
      }),
      secondaryButton: formatMessage({
        id: 'campaignDashboardPage.contentCard.publishContract.confirmation.secondaryButton',
      }),
    },
  };
};

export const alternativeConfirmationConfig = () => {
  const { formatMessage } = useIntl();

  return {
    data: {
      alert: formatMessage({
        id: 'campaignDashboardPage.contentCard.publishContract.confirmationAlternative.alert',
      }),
      description: (
        <span>
          {formatMessage({
            id: 'campaignDashboardPage.contentCard.publishContract.confirmationAlternative.description',
          })}
        </span>
      ),
      primaryButton: formatMessage({
        id: 'campaignDashboardPage.contentCard.publishContract.confirmationAlternative.primaryButton',
      }),
      secondaryButton: formatMessage({
        id: 'campaignDashboardPage.contentCard.publishContract.confirmationAlternative.secondaryButton',
      }),
    },
  };
};
