import { Heading, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { formatDateToEnUs } from '../../../../../utils/formats/FormattedDate/formatted-date';
import { AirdropCardInfoContainer, AirdropCardInfoItem } from '../AirdropCard.styles';

type SnapshotBlockNumberProps = {
  snapshotDate: string;
  blockNumber: number;
};

export const SnapshotBlockNumber: React.FC<SnapshotBlockNumberProps> = ({
  snapshotDate,
  blockNumber,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <AirdropCardInfoContainer marginBottom="medium">
        <AirdropCardInfoItem
          width="auto"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
        >
          <Heading type="H5" size="H5" css={{ marginBottom: '$1' }}>
            {formatMessage({
              id: 'campaignDashboardPage.airdrop.airdropCard.snapshotDate',
            })}
          </Heading>
          <Paragraph size="small" data-testid="airdrop-snapshot-date">
            {formatDateToEnUs(new Date(Number(snapshotDate)).toString())}
          </Paragraph>
        </AirdropCardInfoItem>
      </AirdropCardInfoContainer>

      <AirdropCardInfoContainer marginBottom="large">
        <AirdropCardInfoItem
          width="auto"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
        >
          <Heading type="H5" size="H5" css={{ marginBottom: '$1' }}>
            {formatMessage({
              id: 'campaignDashboardPage.airdrop.airdropCard.blockNumber',
            })}
          </Heading>
          <Paragraph size="small" data-testid="airdrop-block-number">
            #{blockNumber} ETH
          </Paragraph>
        </AirdropCardInfoItem>
      </AirdropCardInfoContainer>
    </>
  );
};
