import { Heading } from '@hexa-ui/components';
import CardField from '../CardField/CardField';
import EditCard from '../EditCard/EditCard';
import { Header, ReviewWrapper } from './ReviewCard.styles';
import {
  airdropCollectionPropsType,
  airdropPropsType,
  campaignPropsType,
  collectionPropsType,
  mintingPropsType,
} from './types';
interface ReviewCardProps {
  props:
    | campaignPropsType
    | collectionPropsType
    | mintingPropsType
    | airdropCollectionPropsType
    | airdropPropsType;
  cardTitle: string;
  step?: number;
  edit?: boolean;
  setStep?: (step: number) => void;
}

const ReviewCard = ({ props, step, cardTitle, edit, setStep }: ReviewCardProps): JSX.Element => {
  return (
    <>
      <ReviewWrapper elevated="minimal" border="large" data-testid="review-card">
        <Header>
          <Heading size="H3">{cardTitle}</Heading>
          {edit ? <EditCard step={step} setStep={setStep} /> : ''}
        </Header>
        {Object.entries(props)
          .filter(([_, value]) => value)
          .map(([key, value]: any, idx: number) => {
            return <CardField key={idx} title={key} description={value} />;
          })}
      </ReviewWrapper>
    </>
  );
};

export default ReviewCard;
