import { useCallback, useContext } from 'react';
import getApiHost from '../../../../services/host/HostService';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { compressCollection } from '../../services/compressCollection/compressCollection';

const useCompressCollection = (): Function => {
  const { campaignInfo } = useContext(CampaignInfoContext);
  const { BEARERTOKEN } = useEnvContext();

  return useCallback(async (reveal: boolean): Promise<void> => {
    try {
      await compressCollection(getApiHost(), BEARERTOKEN, campaignInfo.id, reveal);
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  }, []);
};

export { useCompressCollection };
