import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetCampaign } from '../../../../../../shared-components/CampaignFormAggregator/hooks/useGetCampaign/useGetCampaign';
import { useSaveCampaign } from '../../../../../../shared-components/CampaignFormAggregator/hooks/useSaveCampaign/useSaveCampaign';
import { CampaignInfoContext } from '../../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { Steps } from '../../types';
import useAirdrop from '../useAirdrop/useAirdrop';

type useStepsResult = {
  formStep: number;
  decrementStep: () => void;
  incrementStep: () => void;
  createAirdrop: () => void;
  handleStep: (step: number) => void;
  isDisabled: boolean;
};

const useSteps = (): useStepsResult => {
  const { campaignInfo, campaignInfoError } = useContext(CampaignInfoContext);
  const { getAirdropDraft } = useAirdrop();
  const [formStep, setFormStep] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>();
  const editCampaign = useSaveCampaign();
  const getCampaign = useGetCampaign(campaignInfo.id);
  const toastService = useToast();
  const { formatMessage } = useIntl();
  const hasHolders = getAirdropDraft()?.nftsHolders?.length > 0;
  const isValid =
    hasHolders &&
    !campaignInfoError &&
    !campaignInfo.inputErrors.airdropAddresses &&
    !campaignInfo.inputErrors.airdropNftsHoldersContract &&
    !campaignInfo.inputErrors.airdropNftsHoldersSnapshot;

  useEffect(() => {
    if (formStep == Steps.ADDRESSES_STEP) {
      setIsDisabled(!isValid);
    }
  }, [formStep, campaignInfo]);

  const decrementStep = (): void => {
    setFormStep(formStep - 1);
  };

  const incrementStep = (): void => {
    setFormStep(formStep + 1);
  };

  const handleStep = (step: number) => {
    if (step === Steps.SUMMARY_STEP && (!hasHolders || isDisabled)) {
      return;
    }
    setFormStep(step);
  };

  const createAirdrop = async (): Promise<void> => {
    try {
      await editCampaign();
      getCampaign();
    } catch (error) {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({
          id: 'campaignDashboardPage.airdrop.summary.error.failCreateAirdrop',
        }),
      });
    }
  };

  return {
    formStep,
    incrementStep,
    decrementStep,
    handleStep,
    createAirdrop,
    isDisabled,
  };
};

export default useSteps;
