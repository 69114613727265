import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const { Container, Item } = Grid;

export const AirdropListContainer = styled(Container, {
  width: '100%',
  margin: '0 !important',
  gap: '$6',
});

export const AirdropLoadingContainer = styled(Item, {
  width: '100%',
  height: '50vh',
  margin: '0 !important',
  alignItems: 'center',
  justifyContent: 'center',
});
