import { Trash2 } from '@admin-portal-shared-components/icons';
import { Input, TextArea } from '@admin-portal-shared-components/input';
import { Select, SelectOption } from '@admin-portal-shared-components/select';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputLabel } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  currencyBlurHandler,
  currencyFocusHandler,
  currencyFormatter,
} from '../../../../utils/formats/FormattedCurrency/formattedCurrency';

import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { validateCurrency } from '../../../../utils/validators/currencyValidator/currencyValidator';
import { useDisableInput } from '../../hooks/useDisableInput/useDisableInput';
import Presale from '../Presale/Presale';

const BRAND_OPTIONS = [
  {
    id: '1',
    name: 'Brahma',
  },
  {
    id: '2',
    name: 'Bud Light',
  },
  {
    id: '3',
    name: 'Budweiser',
  },
  {
    id: '4',
    name: 'Skol',
  },
];

const CampaignForm = (): JSX.Element => {
  const { campaignInfo, setCampaignInfoSuccess } = useCampaignInfoContext();
  const { formatMessage } = useIntl();
  const [nftBasePrice, setNftBasePrice] = useState(null);
  const endDate = new Date(campaignInfo.preSale.endDate);
  const validLaunchDate = new Date(endDate);
  validLaunchDate.setDate(validLaunchDate.getDate() + 1);
  const disableInput = useDisableInput(campaignInfo);

  /* istanbul ignore next */
  const selectHandler = (value: string) => {
    setCampaignInfoSuccess({
      ...campaignInfo,
      brand: value,
    });
  };

  const handleRequired = (name: string) => {
    switch (name) {
      case 'campaign':
        return 'campaignFormAggregator.campaignInfo.errors.titleRequired';
      case 'campaignSymbol':
        return 'campaignFormAggregator.campaignInfo.errors.symbolRequired';
      default:
        return 'campaignFormAggregator.campaignInfo.errors.requiredField';
    }
  };

  const inputHandler = (e: React.ChangeEvent<any>, required: boolean) => {
    if (!e.target.value && required) {
      const errorMessage = handleRequired(e.target.name);
      setCampaignInfoSuccess({
        ...campaignInfo,
        [e.target.name]: e.target.value,
        inputErrors: {
          ...campaignInfo.inputErrors,
          [e.target.name]: formatMessage({
            id: errorMessage,
          }),
        },
      });
      return;
    }

    setCampaignInfoSuccess({
      ...campaignInfo,
      [e.target.name]: e.target.value,
      inputErrors: {
        ...campaignInfo.inputErrors,
        [e.target.name]: '',
      },
    });
  };

  const nftBasePriceHandler = (e: React.ChangeEvent<any>) => {
    const price = e.target.value.replace(/[^0-9.]+/g, '');

    const errorMessage = validateCurrency(price);

    setNftBasePrice(price);
    setCampaignInfoSuccess({
      ...campaignInfo,
      nftBasePrice: price,
      inputErrors: {
        ...campaignInfo.inputErrors,
        nftBasePrice: errorMessage
          ? formatMessage({
              id: errorMessage,
            })
          : '',
      },
    });
  };

  const dateHandler = (date: Date) => {
    setCampaignInfoSuccess({
      ...campaignInfo,
      launchDate: date === null ? '' : date.toISOString(),
    });
  };

  const nftBasePriceValue = (): string => {
    if (nftBasePrice == null) {
      return campaignInfo.nftBasePrice
        ? currencyFormatter.format(Number(campaignInfo.nftBasePrice))
        : '';
    }
    return nftBasePrice;
  };

  return (
    <Grid container direction="column" spacing={3} data-testid="campaignInfo">
      <Grid item>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={6}>
            <Select
              width="100%"
              name="brand"
              label={formatMessage({
                id: 'campaignFormAggregator.campaignInfo.labels.brand',
              })}
              placeholder={formatMessage({
                id: 'campaignFormAggregator.campaignInfo.placeholders.brand',
              })}
              shape="square"
              value={campaignInfo.brand}
              onChange={(value) => selectHandler(value)}
              error={campaignInfo.inputErrors.brand}
              data-testid="campaignBrand"
              disabled={disableInput}
            >
              {BRAND_OPTIONS.map((item) => (
                <SelectOption key={item.id} value={item.name}>
                  {item.name}
                </SelectOption>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Input
              width="100%"
              name="externalUrl"
              label={formatMessage({
                id: 'campaignFormAggregator.campaignInfo.labels.url',
              })}
              placeholder={formatMessage({
                id: 'campaignFormAggregator.campaignInfo.placeholders.url',
              })}
              required={false}
              value={campaignInfo.externalUrl}
              onChange={(e) => inputHandler(e, false)}
              hasError={!!campaignInfo.inputErrors.externalUrl}
              errorText={campaignInfo.inputErrors.externalUrl}
              data-testid="campaignURL"
              disabled={disableInput}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={3} alignItems="flex-end" justifyContent="center">
          <Grid item xs={6}>
            <Input
              width="100%"
              name="campaign"
              label={formatMessage({
                id: 'campaignFormAggregator.campaignInfo.labels.name',
              })}
              placeholder={formatMessage({
                id: 'campaignFormAggregator.campaignInfo.placeholders.name',
              })}
              maxLength={100}
              value={campaignInfo.campaign}
              onChange={(e) => inputHandler(e, true)}
              hasError={!!campaignInfo.inputErrors.campaign}
              errorText={campaignInfo.inputErrors.campaign}
              data-testid="campaignName"
              disabled={disableInput}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              width="100%"
              name="nftBasePrice"
              label={formatMessage({
                id: 'campaignFormAggregator.campaignInfo.labels.nftBasePrice',
              })}
              placeholder={'$'}
              value={nftBasePriceValue()}
              onFocus={(e) => currencyFocusHandler(e, setNftBasePrice)}
              onBlur={(e) => currencyBlurHandler(e, setNftBasePrice)}
              onChange={(e) => nftBasePriceHandler(e)}
              hasError={!!campaignInfo.inputErrors.nftBasePrice}
              errorText={campaignInfo.inputErrors.nftBasePrice}
              data-testid="nftBasePrice"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ paddingBottom: '0' }}>
        <TextArea
          width="100%"
          name="campaignDescription"
          label={formatMessage({
            id: 'campaignFormAggregator.campaignInfo.labels.description',
          })}
          placeholder={formatMessage({
            id: 'campaignFormAggregator.campaignInfo.placeholders.description',
          })}
          style={{
            maxHeight: '120px',
          }}
          characterCounter
          maxLength={500}
          required={false}
          value={campaignInfo.campaignDescription}
          onChange={(event) => inputHandler(event, false)}
          data-testid="campaignDescription"
          disabled={disableInput}
        />
      </Grid>
      <Grid item style={{ paddingTop: '0' }}>
        <Grid container spacing={3} alignItems="flex-end" justifyContent="center">
          <Grid item xs={6}>
            <Input
              width="100%"
              name="campaignSymbol"
              label={formatMessage({
                id: 'campaignFormAggregator.campaignInfo.labels.symbol',
              })}
              placeholder={formatMessage({
                id: 'campaignFormAggregator.campaignInfo.placeholders.symbol',
              })}
              value={campaignInfo.campaignSymbol}
              onChange={(event) => inputHandler(event, true)}
              hasError={!!campaignInfo.inputErrors.campaignSymbol}
              errorText={campaignInfo.inputErrors.campaignSymbol}
              data-testid="campaignSymbol"
              disabled={disableInput}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <InputLabel shrink>
                {formatMessage({
                  id: 'campaignFormAggregator.campaignInfo.labels.launchDate',
                })}
                <span style={{ color: 'rgba(0,0,0,0.55)', fontWeight: 'normal' }}>
                  {' '}
                  ({formatMessage({ id: 'campaignFormAggregator.campaignInfo.labels.optional' })})
                </span>
              </InputLabel>
              <KeyboardDatePicker
                fullWidth
                inputVariant="outlined"
                variant="inline"
                InputAdornmentProps={{ position: 'start' }}
                invalidDateMessage={formatMessage({
                  id: 'campaignFormAggregator.campaignInfo.errors.invalidDate',
                })}
                format="MM/dd/yyyy"
                autoOk
                disablePast
                disableToolbar
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <button
                      data-testid="reset-button"
                      onClick={() => {
                        setCampaignInfoSuccess({
                          ...campaignInfo,
                          launchDate: '',
                        });
                      }}
                      style={{
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                      }}
                    >
                      <Trash2 size="large" />
                    </button>
                  ),
                }}
                value={campaignInfo.launchDate || null}
                onChange={(date) => dateHandler(date)}
                data-testid="campaignLaunchDate"
                minDate={campaignInfo.preSale.enable ? validLaunchDate : new Date()}
                minDateMessage={
                  campaignInfo.preSale.enable && campaignInfo.preSale.endDate
                    ? formatMessage({
                        id: 'campaignFormAggregator.campaignInfo.errors.launchDateMinDate',
                      })
                    : formatMessage({
                        id: 'campaignFormAggregator.campaignInfo.errors.noPastDates',
                      })
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
      <Presale />
    </Grid>
  );
};

export default CampaignForm;
