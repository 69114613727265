import React, { createContext, ReactNode, useCallback, useContext, useReducer } from 'react';
import { ICampaign } from '../../../models/campaignModel';
import { ICampaignListAction, initialState } from './campaignList.actions';
import { campaignListReducer } from './campaignList.reducer';
import { ICampaignListContext } from './campaignList.types';

interface ICampaignListContextProps {
  children: ReactNode;
}

export const CampaignListContext = createContext<ICampaignListContext>(initialState);

const CampaignListContextProvider = ({ children }: ICampaignListContextProps) => {
  const [state, dispatch] = useReducer(campaignListReducer, initialState);

  const setCampaignListRequest = useCallback(() => {
    dispatch({
      type: ICampaignListAction.CAMPAIGN_LIST_REQUEST,
      payload: null,
    });
  }, []);

  const setCampaignListSuccess = useCallback((listCampaign: Array<ICampaign>) => {
    dispatch({
      type: ICampaignListAction.CAMPAIGN_LIST_SUCCESS,
      payload: listCampaign,
    });
  }, []);

  const setCampaignListError = useCallback((error: string) => {
    dispatch({
      type: ICampaignListAction.CAMPAIGN_LIST_ERROR,
      payload: error,
    });
  }, []);

  const campaignListValue = {
    campaigns: state.campaigns,
    isLoadingCampaignList: state.isLoadingCampaignList,
    campaignListError: state.campaignListError,
    setCampaignListRequest,
    setCampaignListSuccess,
    setCampaignListError,
  };

  return (
    <CampaignListContext.Provider value={campaignListValue}>
      {children}
    </CampaignListContext.Provider>
  );
};
export const useCampaignListContext = () => {
  return useContext(CampaignListContext);
};
export default React.memo(CampaignListContextProvider);
