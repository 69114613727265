import { Button, Card, Heading, Paragraph, TextLink } from '@hexa-ui/components';
import { Store } from '@hexa-ui/icons';
import { OpenInNew } from '@material-ui/icons';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCampaignInfoContext } from '../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { formatDateToEnUs } from '../../../../../utils/formats/FormattedDate/formatted-date';
import {
  IShopifyResponse,
  useExportShopifyDataHook,
} from '../../../hooks/useExportShopifyData/useExportShopifyDataHook';
import { CardContainerStatus } from '../../CardContainer/types';
import Confirmation from '../Confirmation/Confirmation';
import { CardWrapper, LinksWrapper, TextLinkWrapper } from '../ContentCard.styles';
import Loading from '../Loading/Loading';
import { SetupShopifyProps } from '../types';
import { confirmationConfig } from './config';

const SetupShopify = ({ status }: SetupShopifyProps): JSX.Element => {
  const { campaignInfo, setCampaignInfoSuccess } = useCampaignInfoContext();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const { data } = confirmationConfig();
  const isDisabled = status === CardContainerStatus.DISABLED;
  const exportShopifyData = useExportShopifyDataHook();
  const { formatMessage } = useIntl();
  const toastService = useToast();

  const validateExportedData = (responseData: IShopifyResponse) => {
    if (responseData.success) {
      setCampaignInfoSuccess({
        ...campaignInfo,
        shopify: {
          isExported: true,
          exportedDate: new Date().toString(),
        },
      });
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({
          id: 'campaignDashboardPage.contentCard.setupShopify.toastMessage',
        }),
      });
    } else if (
      !responseData.success &&
      responseData.errors.status === 400 &&
      responseData.errors.message.name.includes('liveCollectionStorage')
    ) {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({
          id: 'campaignDashboardPage.contentCard.setupShopify.errors.collectionNotFound',
        }),
      });
    } else {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({
          id: 'campaignDashboardPage.contentCard.setupShopify.errors.generic',
        }),
      });
    }
  };

  /* istanbul ignore next */
  const handleClick = async () => {
    setIsLoading(true);
    const exportedData = await exportShopifyData(campaignInfo.id);
    validateExportedData(exportedData);
    setIsLoading(false);
  };

  return (
    <CardWrapper data-testid="setup-shopify">
      <Card border="medium" elevated="small" className={isDisabled ? 'card disabled' : 'card'}>
        {!isLoading && (
          <>
            <Store size="xlarge" className="icon" />
            <Heading size="H3">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.setupShopify.header',
              })}
            </Heading>
          </>
        )}

        {!campaignInfo?.shopify?.isExported &&
          campaignInfo.status !== 'Ended' &&
          !confirmationStep &&
          !isLoading && (
            <>
              <Paragraph>
                {formatMessage({
                  id: 'campaignDashboardPage.contentCard.setupShopify.firstState.description',
                })}
              </Paragraph>
              <Button
                variant="secondary"
                size="medium"
                onClick={() => setConfirmationStep(true)}
                data-testid="send-button"
                disabled={isDisabled}
              >
                {formatMessage({
                  id: 'campaignDashboardPage.contentCard.setupShopify.firstState.button',
                })}
              </Button>
            </>
          )}

        {confirmationStep && (
          <Confirmation
            props={data}
            setConfirmationStep={setConfirmationStep}
            confirmationAction={handleClick}
          />
        )}

        {isLoading && (
          <Loading
            message={formatMessage({
              id: 'campaignDashboardPage.contentCard.setupShopify.loadingMessage',
            })}
          />
        )}

        {campaignInfo?.shopify?.isExported && (
          <>
            <Paragraph>
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.setupShopify.secondState.description',
              })}
              {formatDateToEnUs(campaignInfo?.shopify?.exportedDate)}
            </Paragraph>
            <LinksWrapper data-testid="shopify-links-wrapper">
              <TextLinkWrapper>
                <OpenInNew />
                <TextLink href="https://the-nft-marketplace-staging.myshopify.com/" target="_blank">
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.setupShopify.secondState.link',
                  })}
                </TextLink>
              </TextLinkWrapper>
            </LinksWrapper>
          </>
        )}
      </Card>
    </CardWrapper>
  );
};

export default SetupShopify;
