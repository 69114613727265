import { BlobServiceClient } from '@azure/storage-blob';
import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { useUploadToIpfs } from '../../../../pages/Home/hooks/useUploadToIpfs/useUploadToIpfs';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { validateCollectionJson } from '../../../../utils/validators/collectionJsonValidator/collectionJsonValidator';

import { uploadFile } from '../../services/uploadFile/uploadFile';

export enum AzureStorage {
  collection = 'collection',
  placeholder = 'placeholder',
}

const useUploadCollection = (): Function => {
  const { campaignInfo, setIsUploading, setCampaignInfoSuccess } = useContext(CampaignInfoContext);
  const uploadToIpfs = useUploadToIpfs();
  const { STORAGE_ACCOUNT_NAME } = useEnvContext();

  return useCallback(
    async (
      files: File[],
      sasToken: string,
      setUploadProgress: Dispatch<SetStateAction<number>>,
      abortControllerSignal: AbortSignal,
      azurePath: AzureStorage
    ): Promise<void> => {
      try {
        let nftQuantity = 0;
        const accountName = STORAGE_ACCOUNT_NAME;
        const containerName = 'beesnftstorage';

        const blobService = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        for (const file of files) {
          if (file.type === 'application/json') {
            validateCollectionJson(file);
            nftQuantity++;
          }
          const blockBlobClient = containerClient.getBlockBlobClient(
            campaignInfo.id + `/${azurePath}/` + file.name
          );
          await uploadFile(blockBlobClient, file, abortControllerSignal);
          setUploadProgress(((files.indexOf(file) + 1) / files.length) * 100);
        }

        if (azurePath === AzureStorage.collection) {
          setCampaignInfoSuccess({
            ...campaignInfo,
            liveCollectionStorage: {
              uploaded: true,
              baseUri: '',
              filesCount: files.length,
              nftQuantity: nftQuantity,
              uploadDate: new Date().toISOString(),
            },
          });
        } else {
          await uploadToIpfs(true);
          setCampaignInfoSuccess({
            ...campaignInfo,
            placeholderCollectionStorage: {
              uploaded: true,
              baseUri: '',
              filesCount: files.length,
              nftQuantity: nftQuantity,
              uploadDate: new Date().toISOString(),
            },
          });
        }
      } catch (error) {
        setIsUploading(false);
        setCampaignInfoSuccess({
          ...campaignInfo,
          liveCollectionStorage: {
            uploaded: false,
            baseUri: '',
            filesCount: 0,
            nftQuantity: 0,
            uploadDate: null,
          },
        });

        throw new Error(error.message);
      }
    },
    [campaignInfo, setIsUploading, setCampaignInfoSuccess]
  );
};

export { useUploadCollection };
