import { useState } from 'react';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import CustomStepper from '../../../CustomStepper/CustomStepper';
import { Steps } from '../../CampaignFormAggregator.types';
import CancelUploadDialog from '../CancelUploadDialog/CancelUploadDialog';

import { CampaignStepperProps } from './types';

export const stepsData = [
  {
    label: 'campaignFormAggregator.stepper.firstStep.label',
    description: 'campaignFormAggregator.stepper.firstStep.description',
  },
  {
    label: 'campaignFormAggregator.stepper.secondStep.label',
    description: 'campaignFormAggregator.stepper.secondStep.description',
  },
  {
    label: 'campaignFormAggregator.stepper.thirdStep.label',
    description: 'campaignFormAggregator.stepper.thirdStep.description',
  },
  {
    label: 'campaignFormAggregator.stepper.fourthStep.label',
    description: 'campaignFormAggregator.stepper.fourthStep.description',
  },
];

const CampaignStepper: React.FC<CampaignStepperProps> = ({
  formStep,
  setStep,
  goToStep,
  setGoToStep,
  abortControllerRef,
}) => {
  const { isCampaignFormValid, isUploading, campaignInfo } = useCampaignInfoContext();
  const [isCancelUploadDialogOpen, setIsCancelUploadDialogOpen] = useState(false);

  const handleCancelUploadDialogOpen = () => {
    setIsCancelUploadDialogOpen(!isCancelUploadDialogOpen);
  };

  const shouldDisableMintingStep =
    formStep === Steps.COLLECTION_STEP &&
    campaignInfo.lateReveal &&
    !campaignInfo.placeholderCollectionStorage?.uploaded;

  const handleStep = (index: number): void => {
    if (!isCampaignFormValid || shouldDisableMintingStep) {
      return;
    }

    if (isUploading) {
      setGoToStep(index);
      handleCancelUploadDialogOpen();
    } else {
      setStep(index);
    }
  };

  return (
    <>
      <CustomStepper stepsData={stepsData} activeStep={formStep} handleStepClick={handleStep} />
      <CancelUploadDialog
        isOpen={isCancelUploadDialogOpen}
        setIsOpen={handleCancelUploadDialogOpen}
        goToStep={goToStep}
        setGoToStep={setGoToStep}
        setStep={setStep}
        title="campaignFormAggregator.collectionForm.cancelUploadDialog.leavePageTitle"
        description="campaignFormAggregator.collectionForm.cancelUploadDialog.leavePageDescription"
        leftButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.leaveButton"
        rightButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.goBackButton"
        abortControllerRef={abortControllerRef}
      />
    </>
  );
};

export default CampaignStepper;
