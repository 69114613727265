import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

import { Button, Card, Heading, Paragraph, TextLink } from '@hexa-ui/components';
import { Upload } from '@hexa-ui/icons';
import { FileCopyOutlined, OpenInNew } from '@material-ui/icons';
import { TypeToast, useToast } from 'admin-portal-shared-services';

import Confirmation from '../Confirmation/Confirmation';
import Loading from '../Loading/Loading';

import { useNavigate } from 'react-router-dom';
import { useCampaignInfoContext } from '../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { formattedDate } from '../../../../../utils/formats/FormattedDate/formatted-date';
import { useEnvContext } from '../../../../../utils/hooks/EnvProvider/EnvProvider';
import { BlockchainNetwork } from '../../../../Home/hooks/useGenerateContract/types';
import { useGenerateContract } from '../../../../Home/hooks/useGenerateContract/useGenerateContract';
import { CardWrapper, LinksWrapper, TextLinkWrapper } from '../ContentCard.styles';
import { alternativeConfirmationConfig, confirmationConfig } from './config';

const PublishContract = (): JSX.Element => {
  const { DEFAULT_NETWORK } = useEnvContext();
  /* istanbul ignore next */
  const defaultNetwork = DEFAULT_NETWORK ?? BlockchainNetwork.GOERLI;

  const { campaignInfo } = useCampaignInfoContext();
  const generateContract = useGenerateContract(campaignInfo.id, true);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const { data } = confirmationConfig(Boolean(campaignInfo?.testNetContract?.hash));
  const alternativeConfig = alternativeConfirmationConfig();
  const { formatMessage } = useIntl();
  const toastService = useToast();
  const navigate = useNavigate();

  const handleClick = async () => {
    setIsLoading(true);
    const errorMessage = await generateContract();
    if (errorMessage) {
      toastService.notify({ type: TypeToast.ERROR, message: formatMessage({ id: errorMessage }) });
    } else {
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({
          id: 'campaignDashboardPage.contentCard.publishContract.toastMessage',
        }),
      });
    }
    setIsLoading(false);
  };

  const handleGoToCollection = () => {
    navigate(`${location.pathname}?currentTabId=campaign-info&currentStep=1`);
  };

  return (
    <CardWrapper data-testid="publish-contract">
      <Card border="medium" elevated="small" className="card">
        {!isLoading && !confirmationStep && !campaignInfo?.liveContract?.isPublishing && (
          <>
            <Upload size="xlarge" className="icon" />
            <Heading size="H3">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.publishContract.header',
              })}
            </Heading>

            {!campaignInfo?.liveContract?.hash ? (
              <>
                <Paragraph>
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.publishContract.firstState.description',
                  })}
                </Paragraph>
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => setConfirmationStep(true)}
                  data-testid="publish-button"
                  disabled={campaignInfo?.testNetContract?.isPublishing}
                >
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.publishContract.firstState.button',
                  })}
                </Button>
              </>
            ) : (
              <>
                <Paragraph className="longer">
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.publishContract.secondState.description',
                  })}
                  {campaignInfo?.liveContract?.publishedDate &&
                    formattedDate(campaignInfo?.liveContract?.publishedDate.toString(), 'day')}
                </Paragraph>
                <LinksWrapper data-testid="publish-links-wrapper">
                  <CopyToClipboard
                    text={campaignInfo?.liveContract && campaignInfo?.liveContract?.hash}
                  >
                    <TextLinkWrapper>
                      <FileCopyOutlined />
                      <TextLink>
                        {formatMessage({
                          id: 'campaignDashboardPage.contentCard.publishContract.secondState.firstLink',
                        })}
                      </TextLink>
                    </TextLinkWrapper>
                  </CopyToClipboard>
                  <TextLinkWrapper>
                    <OpenInNew />
                    <TextLink
                      href={`https://${
                        defaultNetwork === BlockchainNetwork.ETHEREUM_MAINNET ? '' : 'goerli.'
                      }etherscan.io/address/${campaignInfo?.liveContract?.hash}`}
                      target="_blank"
                      data-testid="publish-contract-url"
                    >
                      {formatMessage({
                        id: 'campaignDashboardPage.contentCard.publishContract.secondState.secondLink',
                      })}
                    </TextLink>
                  </TextLinkWrapper>
                </LinksWrapper>
              </>
            )}
          </>
        )}

        {confirmationStep && (
          <>
            {(campaignInfo.lateReveal && !campaignInfo.placeholderCollectionStorage.uploaded) ||
            (!campaignInfo.lateReveal && !campaignInfo.liveCollectionStorage.uploaded) ? (
              <Confirmation
                props={alternativeConfig.data}
                setConfirmationStep={setConfirmationStep}
                confirmationAction={handleGoToCollection}
              />
            ) : (
              <Confirmation
                props={data}
                setConfirmationStep={setConfirmationStep}
                confirmationAction={handleClick}
              />
            )}
          </>
        )}

        {(campaignInfo?.liveContract?.isPublishing || isLoading) && (
          <Loading
            message={formatMessage({
              id: 'campaignDashboardPage.contentCard.publishContract.loadingMessage',
            })}
          />
        )}
      </Card>
    </CardWrapper>
  );
};

export default PublishContract;
