import { ICampaignListAction } from './campaignList.actions';
import { ICampaignListContext, ICampaignListReducerAction } from './campaignList.types';

export const campaignListReducer = (
  state: ICampaignListContext,
  action: ICampaignListReducerAction
): ICampaignListContext => {
  switch (action.type) {
    case ICampaignListAction.CAMPAIGN_LIST_REQUEST: {
      return {
        ...state,
        isLoadingCampaignList: true,
        campaigns: [],
        campaignListError: null,
      };
    }
    case ICampaignListAction.CAMPAIGN_LIST_SUCCESS: {
      return {
        ...state,
        isLoadingCampaignList: false,
        campaigns: action.payload,
        campaignListError: null,
      };
    }
    case ICampaignListAction.CAMPAIGN_LIST_ERROR: {
      return {
        ...state,
        isLoadingCampaignList: false,
        campaigns: [],
        campaignListError: action.payload,
      };
    }
    default:
      return state;
  }
};
