import { TextButton } from '@hexa-ui/components';
import { Edit2 } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';

interface EditCardProps {
  step: number;
  setStep: (step: number) => void;
}

const EditCard = ({ step, setStep }: EditCardProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <TextButton
      icon={Edit2}
      iconPosition="leading"
      onClick={() => setStep(step)}
      data-testid="edit-card"
    >
      {formatMessage({ id: 'campaignFormAggregator.reviewForm.edit' })}
    </TextButton>
  );
};

export default EditCard;
