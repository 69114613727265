import { styled } from '@bees/vision-tokens';

export const RevealContainer = styled('div', {
  maxWidth: '360px',
  marginBottom: '$8',
});

export const Wrapper = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '$2',
});

export const ButtonContainer = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '$4 0',
});

export const ReplacePlacheholderContainer = styled('div', {
  marginTop: '$4',
});

export const UploadPlaceholderContainer = styled('div', {
  '*:not(:last-child)': {
    display: 'block',
    marginBottom: '$4',
  },
  marginTop: '$4',
  marginBottom: '$4',
});
