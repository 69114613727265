import { useCallback, useContext } from 'react';
import { IWalletInfo } from '../../../../models/campaignModel';
import { BlockchainNetwork } from '../../../../pages/Home/hooks/useGenerateContract/types';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { allowlistCsvValidator } from '../../../../utils/validators/allowlistCsvValidator/allowlistCsvValidator';
import { walletValidator } from '../../../../utils/validators/walletValidator/walletValidator';

const useAddMinters = (): Function => {
  const { campaignInfo, setCampaignInfoSuccess } = useContext(CampaignInfoContext);

  return useCallback(
    async (file: File): Promise<void> => {
      try {
        const csvContent = await allowlistCsvValidator(file);

        walletValidator(csvContent, BlockchainNetwork.ETHEREUM_MAINNET);

        const allowListArray = csvContent.map((item: string): IWalletInfo => {
          return Object.assign({
            walletAddress: item,
          });
        });

        setCampaignInfoSuccess({
          ...campaignInfo,
          minting: {
            ...campaignInfo.minting,
            allowList: allowListArray,
            uploadDate: new Date().toISOString(),
          },
        });
      } catch (error) {
        throw new Error(error.message);
      }
    },
    [campaignInfo, setCampaignInfoSuccess]
  );
};

export { useAddMinters };
