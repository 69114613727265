import { ThemeProvider } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ActionButtons from '../../../../shared-components/ActionButtons/ActionButtons';
import CustomStepper from '../../../../shared-components/CustomStepper/CustomStepper';
import { StepsData } from '../../../../shared-components/CustomStepper/types';
import { campaignDashboardFormTheme } from '../../../../shared-components/Themes/campaignDashboardFormTheme';
import { AirdropDataCard } from '../AirdropWrapper/AirdropDataCard/AirdropDataCard';
import { AirdropFields } from '../AirdropWrapper/AirdropFields/AirdropFields';
import Recipients from '../Recipients/Recipients';
import Summary from '../Summary/Summary';
import { AirdropCard, AirdropContainer, AirdropItem } from './AirdropContent.styles';
import useSteps from './hooks/useSteps/useSteps';
import { Steps } from './types';

const stepsData: Array<StepsData> = [
  {
    label: 'campaignDashboardPage.airdrop.airdropContent.steps.recipients.label',
    description: 'campaignDashboardPage.airdrop.airdropContent.steps.recipients.description',
  },
  {
    label: 'campaignDashboardPage.airdrop.airdropContent.steps.addresses.label',
    description: 'campaignDashboardPage.airdrop.airdropContent.steps.addresses.description',
  },
  {
    label: 'campaignDashboardPage.airdrop.airdropContent.steps.summary.label',
    description: 'campaignDashboardPage.airdrop.airdropContent.steps.summary.description',
  },
];

const AirdropContent = () => {
  const { formatMessage } = useIntl();
  const { formStep, incrementStep, decrementStep, handleStep, createAirdrop, isDisabled } =
    useSteps();

  return (
    <AirdropCard elevated="small" border="small" data-testid="airdrop-content">
      <ThemeProvider theme={campaignDashboardFormTheme}>
        <AirdropContainer
          flexDirection="row"
          justifyContent="start"
          alignItems="start"
          background="gray"
          css={{ width: '100%', height: 'auto' }}
        >
          <AirdropItem xs={4} css={{ width: '100%', height: '100%' }}>
            <CustomStepper
              activeStep={formStep}
              stepsData={stepsData}
              handleStepClick={handleStep}
            />
          </AirdropItem>
          <AirdropItem
            xs={8}
            background="white"
            css={{ padding: '$8', height: 'calc(100vh - 226px)', width: '100%' }}
          >
            <AirdropContainer
              flexDirection="column"
              justifyContent="spaceBetween"
              alignItems="start"
              background="none"
              css={{ width: '100%', height: '100%' }}
            >
              <AirdropItem css={{ marginBottom: '$8', width: '100%', height: 'auto' }}>
                {formStep === Steps.RECIPIENTS_STEP && <Recipients />}
                {formStep === Steps.ADDRESSES_STEP && (
                  <div data-testid="AddressesForm" style={{ width: '100%' }}>
                    <AirdropFields />
                    <AirdropDataCard />
                  </div>
                )}
                {formStep === Steps.SUMMARY_STEP && <Summary />}
              </AirdropItem>
              <AirdropItem css={{ width: '100%', height: 'auto' }}>
                <ActionButtons
                  leftButton={{
                    text: formatMessage({
                      id: 'campaignDashboardPage.airdrop.actionButtons.leftButtonText.back',
                    }),
                    onClick: decrementStep,
                    isDisabled: false,
                    hide: formStep === Steps.RECIPIENTS_STEP ? true : false,
                  }}
                  rightButton={{
                    text:
                      formStep === Steps.SUMMARY_STEP
                        ? 'campaignDashboardPage.airdrop.actionButtons.rightButtonText.create'
                        : 'campaignDashboardPage.airdrop.actionButtons.rightButtonText.next',
                    onClick: formStep === Steps.SUMMARY_STEP ? createAirdrop : incrementStep,
                    isDisabled: isDisabled,
                    hide: false,
                  }}
                />
              </AirdropItem>
            </AirdropContainer>
          </AirdropItem>
        </AirdropContainer>
      </ThemeProvider>
    </AirdropCard>
  );
};

export default AirdropContent;
