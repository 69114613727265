import { useCallback, useContext } from 'react';
import getApiHost from '../../../../services/host/HostService';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { uploadToIpfsErrorMapper } from '../../../Home/mappers/uploadToIpfsErrorMapper/uploadToIpfsErrorMapper';
import { uploadToIpfsService } from '../../services/uploadToIpfs/uploadToIpfs';
import { CampaignInfoContext } from './../../../../utils/context/campaignInfoContext/campaignInfo.context';

type Hook = () => (lateReveal: boolean) => Promise<string>;
const useUploadToIpfs: Hook = () => {
  const { setCampaignInfoSuccess, campaignInfo } = useContext(CampaignInfoContext);
  const { BEARERTOKEN } = useEnvContext();

  return useCallback(
    async (lateReveal: boolean) => {
      try {
        const response = await uploadToIpfsService(
          getApiHost(),
          BEARERTOKEN,
          campaignInfo.id,
          lateReveal
        );

        if (lateReveal) {
          campaignInfo.ipfsPlaceholderCollection = response?.data;
        } else {
          campaignInfo.ipfsLiveCollection = response?.data;
        }

        setCampaignInfoSuccess(campaignInfo);
        return '';
      } catch (error) {
        console.error(error?.response?.data?.name);
        if (error?.response?.data?.name) {
          return uploadToIpfsErrorMapper(error?.response?.data?.name);
        } else {
          return error.message;
        }
      }
    },
    [setCampaignInfoSuccess, getApiHost(), BEARERTOKEN, campaignInfo]
  );
};

export { useUploadToIpfs };
