import { Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ReviewWrapper = styled(Card, {
  width: '100%',
  margin: '0 0 $6 0',
  padding: '$6 $10 $6 $8',
  rowGap: '$4',
  border: '2px solid $neutral30',
});

export const Header = styled('header', {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '$1',
  marginBottom: '$2',
});
