import { Input, Paragraph, Radio } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import CustomCurrencyInput from '../CustomCurrencyInput/CustomCurrencyInput';
import { RadioGroupWrapper, RadioItemDescriptionWrapper, RadioWrapper } from './CustomRadio.styles';
import { CustomRadioProps } from './types';

const { Item } = Radio;

const CustomRadio: React.FC<CustomRadioProps> = ({ name, radioData, isCurrencyInput }) => {
  const { formatMessage } = useIntl();

  return (
    <RadioGroupWrapper data-testid={`radio-group-${name}-wrapper`}>
      {radioData.map((radio, index) => (
        <RadioWrapper
          key={`radio-wrapper-${name}${index}`}
          checked={radio.checked}
          data-testid={`radio-wrapper-${radio.testId ?? name}${index}`}
        >
          <Item
            id={`radio-item-${name}${index}`}
            checked={radio.checked} // to be changed in the future
            label={formatMessage({ id: radio.label })}
            value={radio.value}
            onClick={() => radio.handler(radio.value)}
            disabled={radio.disabled}
            data-testid={`radio-item-${name}${index}`}
          />

          <RadioItemDescriptionWrapper color={radio.checked} hasInput={radio.hasInput}>
            <Paragraph size="small">
              {formatMessage({
                id: radio.description,
              })}
            </Paragraph>

            {radio.hasInput && radio.checked && (
              <>
                {isCurrencyInput ? (
                  <CustomCurrencyInput
                    name={radio.inputData.name}
                    value={radio.inputData.value}
                    hasError={radio.inputData.hasError}
                    errorMessage={radio.inputData.errorMessage}
                    valueHandler={radio.inputData.valueHandler}
                    handler={radio.inputData.handler}
                    autoFocus={true}
                    testId={radio.inputData.testId}
                    disabled={!radio.checked}
                  />
                ) : (
                  <Input required />
                )}
              </>
            )}
          </RadioItemDescriptionWrapper>
        </RadioWrapper>
      ))}
    </RadioGroupWrapper>
  );
};

export default CustomRadio;
