import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const { Container } = Grid;

export const ActionButtonContainer = styled(Container, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
