import axios from 'axios';

export const revealCollectionService = async (
  baseUrl: string,
  bearerToken: string,
  id: string
): Promise<any> => {
  return axios.post(
    `${baseUrl}campaign/${id}/reveal`,
    {},
    {
      headers: { authorization: bearerToken },
    }
  );
};
