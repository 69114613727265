import { Dialog } from '@admin-portal-shared-components/dialog';
import { Button, Heading, LoadingDots, Paragraph } from '@hexa-ui/components';
import { Grid } from '@material-ui/core';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { DropzoneArea } from 'material-ui-dropzone';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useUploadToIpfs } from '../../../../pages/Home/hooks/useUploadToIpfs/useUploadToIpfs';
import { FileUploadIcon } from '../../../../shared-components/icons/FileUploadIcon';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { formatDateToEnUs } from '../../../../utils/formats/FormattedDate/formatted-date';
import { useAzureManagement } from '../../hooks/useAzureManagement/useAzureManagement';
import { useNftQuantityValidator } from '../../hooks/useNftQuantityValidator/useNftQuantityValidator';
import { useSaveCampaign } from '../../hooks/useSaveCampaign/useSaveCampaign';
import { AzureStorage } from '../../hooks/useUploadCollection/useUploadCollection';
import { CollectionWrapper } from '../CollectionForm/CollectionForm.styles';
import {
  UploadPlaceHolderButtonsWrapper,
  UploadPlaceHolderParagraphsWrapper,
  UploadPlaceholderWrapper,
} from './UploadPlaceholderModal.styles';

export enum UploadPlaceholderStep {
  dropzone = 'DROPZONE',
  loading = 'LOADING',
  replace = 'REPLACE',
}

type IProps = {
  showModal: boolean;
  handleModal: () => void;
};

const UploadPlaceholderModal: React.FC<IProps> = ({ showModal, handleModal }): JSX.Element => {
  const { campaignInfo } = useCampaignInfoContext();
  const { formatMessage } = useIntl();
  const nftQuantityValidator = useNftQuantityValidator();
  const azureManagement = useAzureManagement();
  const toastService = useToast();
  let uploadPlaceholderAbortControllerRef = useRef<AbortController>(new AbortController());
  const [shouldEdit, setShouldEdit] = useState(false);
  const [enableDropZone, setDropZone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const uploadToIpfs = useUploadToIpfs();
  const editCampaign = useSaveCampaign();
  const acceptedFileTypes = ['application/json'];
  const [, setDummy] = useState();

  /* istanbul ignore next */
  useEffect(() => {
    const triggerUploadToIpfs = async (): Promise<void> => {
      await editCampaign();
      await uploadToIpfs(true);
    };
    if (shouldEdit) {
      triggerUploadToIpfs().then(() => {});
      setIsLoading(false);
      setShouldEdit(false);
    }
  }, [shouldEdit]);

  const showDropZone = () => {
    setDropZone((prev) => !prev);
  };

  const currentStep = useMemo((): UploadPlaceholderStep => {
    if (isLoading) return UploadPlaceholderStep.loading;
    if (enableDropZone || !campaignInfo.placeholderCollectionStorage.uploaded)
      return UploadPlaceholderStep.dropzone;
    if (campaignInfo.placeholderCollectionStorage.uploaded) return UploadPlaceholderStep.replace;
  }, [campaignInfo, isLoading, enableDropZone]);

  const handlePlaceholder = async (files: File[]) => {
    if (nftQuantityValidator(files.length)) {
      setIsLoading((prev) => !prev);
      await azureManagement({
        files,
        azureStorage: AzureStorage.placeholder,
        abortControllerRef: uploadPlaceholderAbortControllerRef,
        campaignId: campaignInfo.id,
        setIsUploading: setDummy,
        setUploadProgress: setDummy,
        setShouldEdit,
      });
      setIsLoading(false);
      setDropZone(false);
    } else {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({
          id: 'campaignFormAggregator.collectionForm.toast.invalidFilesNumber',
        }),
      });
    }
  };

  return (
    <CollectionWrapper isVisible={showModal} data-testid="uploadPlaceholderModalDialog">
      <Dialog
        onClose={handleModal}
        open={showModal}
        title={
          <Heading size="H3">
            {formatMessage({ id: 'campaignFormAggregator.collectionForm.revealDialog.title' })}
          </Heading>
        }
      >
        {currentStep == UploadPlaceholderStep.dropzone && (
          <UploadPlaceholderWrapper data-testid="uploadPlaceholderModalDropzoneArea">
            <DropzoneArea
              onDrop={(files) => handlePlaceholder(files)}
              dropzoneText={formatMessage({
                id: 'campaignFormAggregator.collectionForm.revealDialog.description',
              })}
              acceptedFiles={acceptedFileTypes}
              showAlerts={false}
              showPreviewsInDropzone={false}
              filesLimit={100000}
              maxFileSize={10000000}
              inputProps={{ id: 'fileUploader' }}
              disableRejectionFeedback={true}
              //@ts-ignore
              Icon={FileUploadIcon}
            />
            <Button variant="secondary" style={{ position: 'absolute', bottom: '90px' }}>
              {formatMessage({
                id: 'campaignFormAggregator.collectionForm.revealDialog.buttons.browseFiles',
              })}
            </Button>
          </UploadPlaceholderWrapper>
        )}

        {currentStep == UploadPlaceholderStep.loading && (
          <UploadPlaceholderWrapper data-testid="uploadPlaceholderModalLoading">
            <Paragraph css={{ marginBottom: '$4' }}>
              {formatMessage({
                id: 'campaignFormAggregator.collectionForm.revealDialog.loadingMsg',
              })}
            </Paragraph>
            <Grid item>
              <LoadingDots size="xlarge" />
            </Grid>
          </UploadPlaceholderWrapper>
        )}
        {currentStep == UploadPlaceholderStep.replace && (
          <>
            <UploadPlaceHolderParagraphsWrapper>
              <Paragraph>
                {formatMessage({
                  id: 'campaignFormAggregator.collectionForm.revealDialog.filesNumber',
                })}
                : {campaignInfo.placeholderCollectionStorage?.filesCount}
              </Paragraph>
              <Paragraph>
                {formatMessage({
                  id: 'campaignFormAggregator.collectionForm.revealDialog.lastUploaded',
                })}
                : {formatDateToEnUs(campaignInfo.placeholderCollectionStorage?.uploadDate)}
              </Paragraph>
            </UploadPlaceHolderParagraphsWrapper>
            <UploadPlaceHolderButtonsWrapper>
              <Button
                variant="secondary"
                size="medium"
                data-testid="uploadPlaceholderModalReplaceButton"
                onClick={showDropZone}
              >
                {formatMessage({
                  id: 'campaignFormAggregator.collectionForm.revealDialog.buttons.replace',
                })}
              </Button>
              <Button
                onClick={handleModal}
                variant="primary"
                size="medium"
                data-testid="uploadPlaceholderModalOkButton"
              >
                {formatMessage({
                  id: 'campaignFormAggregator.collectionForm.revealDialog.buttons.ok',
                })}
              </Button>
            </UploadPlaceHolderButtonsWrapper>
          </>
        )}
      </Dialog>
    </CollectionWrapper>
  );
};

export default UploadPlaceholderModal;
