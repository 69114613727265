import { Heading, Paragraph } from '@hexa-ui/components';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

interface CardFieldProps {
  title: string;
  description: string;
}

const CardField = ({ title, description }: CardFieldProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Grid container direction="column" data-testid="card-field">
      <Heading size="H5" css={{ marginBottom: '$1' }}>
        {formatMessage({ id: title })}
      </Heading>
      <Paragraph>{description}</Paragraph>
    </Grid>
  );
};

export default CardField;
