import { useCallback, useContext } from 'react';
import { CampaignInfoContext } from './../../../../utils/context/campaignInfoContext/campaignInfo.context';

type Hook = () => (totalFiles: number) => boolean;

const useNftQuantityValidator: Hook = () => {
  const { campaignInfo } = useContext(CampaignInfoContext);

  return useCallback(
    (totalFiles: number): boolean => {
      if (campaignInfo.lateReveal) {
        if (campaignInfo.liveContract?.hash.length > 0) {
          if (campaignInfo.placeholderCollectionStorage?.uploaded) {
            return Boolean(campaignInfo.placeholderCollectionStorage?.filesCount === totalFiles);
          } else if (campaignInfo.liveCollectionStorage?.uploaded) {
            return Boolean(campaignInfo.liveCollectionStorage?.filesCount === totalFiles);
          }
        } else return true;
      }
      return true;
    },
    [campaignInfo]
  );
};

export { useNftQuantityValidator };
