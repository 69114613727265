import { BlobItem, ContainerClient } from '@azure/storage-blob';

const listCollection = async (
  containerClient: ContainerClient,
  prefix: string,
  abortControllerSignal?: AbortSignal
): Promise<AsyncIterableIterator<BlobItem>> => {
  return containerClient.listBlobsFlat({
    prefix,
    abortSignal: abortControllerSignal,
  });
};

export { listCollection };
