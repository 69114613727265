import { styled } from '@hexa-ui/theme';

export const AirdropFieldsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',

  width: '100%',
});
