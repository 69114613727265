import { ICampaignForm } from '../../../../models/campaignFormModel';
import { currencyFormatter } from '../../../../utils/formats/FormattedCurrency/formattedCurrency';
import { formatDateToEnUs } from '../../../../utils/formats/FormattedDate/formatted-date';

export const config = (campaignInfo: ICampaignForm) => {
  const campaignProps = {
    'campaignFormAggregator.reviewForm.campaignCard.brand': campaignInfo.brand,
    'campaignFormAggregator.reviewForm.campaignCard.campaignTitle': campaignInfo.campaign,
    'campaignFormAggregator.reviewForm.campaignCard.description': campaignInfo.campaignDescription,
    'campaignFormAggregator.reviewForm.campaignCard.brandURL': campaignInfo.externalUrl,
    'campaignFormAggregator.reviewForm.campaignCard.pricePerNft': currencyFormatter.format(
      Number(campaignInfo.nftBasePrice)
    ),
    'campaignFormAggregator.reviewForm.campaignCard.symbol': campaignInfo.campaignSymbol,
    'campaignFormAggregator.reviewForm.campaignCard.launchDate': formatDateToEnUs(
      campaignInfo.launchDate
    ),
  };

  const collectionProps = setCollectionCardInfo(campaignInfo);

  const mintingProps = {
    'campaignFormAggregator.reviewForm.mintingCard.timing': 'Post-minting',
    'campaignFormAggregator.reviewForm.mintingCard.blockchain': campaignInfo.minting?.blockChain,
    'campaignFormAggregator.reviewForm.mintingCard.gasFee': campaignInfo.minting?.smartGas
      ? 'Smart Gas'
      : currencyFormatter.format(campaignInfo.minting?.gasFeeCap),
    'campaignFormAggregator.reviewForm.mintingCard.numberOfWallets':
      campaignInfo.minting?.allowList?.length,
    'campaignFormAggregator.reviewForm.mintingCard.uploaded': formatDateToEnUs(
      campaignInfo.minting?.uploadDate?.toString()
    ),
  };

  if (campaignInfo.preSale.enable) {
    return {
      campaignProps: {
        ...campaignProps,
        'campaignFormAggregator.reviewForm.campaignCard.preSale.enabled':
          campaignInfo.preSale?.enable,
        'campaignFormAggregator.reviewForm.campaignCard.preSale.nftBasePrice':
          currencyFormatter.format(Number(campaignInfo.preSale?.basePrice)),
        'campaignFormAggregator.reviewForm.campaignCard.preSale.startDate': formatDateToEnUs(
          campaignInfo.preSale?.startDate
        ),
        'campaignFormAggregator.reviewForm.campaignCard.preSale.endDate': formatDateToEnUs(
          campaignInfo.preSale?.endDate
        ),
        'campaignFormAggregator.reviewForm.campaignCard.preSale.folderName': 'pre_sale.zip',
        'campaignFormAggregator.reviewForm.campaignCard.preSale.uploaded': 'Jul 10, 2022',
      },
      collectionProps,
      mintingProps,
    };
  }

  return {
    campaignProps,
    collectionProps,
    mintingProps,
  };
};

const setCollectionCardInfo = (campaignInfo: ICampaignForm): any => {
  if (campaignInfo.lateReveal) {
    if (campaignInfo.liveCollectionStorage?.uploaded) {
      return {
        'campaignFormAggregator.reviewForm.collectionCard.lateReveal': campaignInfo.lateReveal,
        'campaignFormAggregator.reviewForm.collectionCard.placeholderCount':
          campaignInfo.placeholderCollectionStorage?.filesCount,
        'campaignFormAggregator.reviewForm.collectionCard.uploadedPlaceholder': formatDateToEnUs(
          campaignInfo.placeholderCollectionStorage?.uploadDate?.toString()
        ),
        'campaignFormAggregator.reviewForm.collectionCard.collectionCount':
          campaignInfo.liveCollectionStorage?.filesCount,
        'campaignFormAggregator.reviewForm.collectionCard.uploadedCollection': formatDateToEnUs(
          campaignInfo.liveCollectionStorage?.uploadDate?.toString()
        ),
      };
    } else {
      return {
        'campaignFormAggregator.reviewForm.collectionCard.lateReveal': campaignInfo.lateReveal,
        'campaignFormAggregator.reviewForm.collectionCard.placeholderCount':
          campaignInfo.placeholderCollectionStorage?.filesCount,
        'campaignFormAggregator.reviewForm.collectionCard.uploadedPlaceholder': formatDateToEnUs(
          campaignInfo.placeholderCollectionStorage?.uploadDate?.toString()
        ),
      };
    }
  }

  if (campaignInfo.liveCollectionStorage.uploaded) {
    return {
      'campaignFormAggregator.reviewForm.collectionCard.collectionCount':
        campaignInfo.liveCollectionStorage?.filesCount,
      'campaignFormAggregator.reviewForm.collectionCard.uploadedCollection': formatDateToEnUs(
        campaignInfo.liveCollectionStorage?.uploadDate?.toString()
      ),
    };
  }

  return {
    'campaignFormAggregator.reviewForm.collectionCard.empty':
      !campaignInfo.liveCollectionStorage?.uploaded,
  };
};
