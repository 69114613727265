import { useCallback, useContext } from 'react';
import { useEnvContext } from './../../../../utils/hooks/EnvProvider/EnvProvider';

import getApiHost from '../../../../services/host/HostService';
import { campaignInfoInitialState } from '../../../../utils/context/campaignInfoContext/campaignInfo.actions';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { getCampaignService } from '../../services/getCampaign/getCampaign';

const useGetCampaign = (id: string): VoidFunction => {
  const { setCampaignInfoError, setCampaignInfoSuccess, setCampaignInfoRequest } =
    useContext(CampaignInfoContext);
  const { BEARERTOKEN } = useEnvContext();

  return useCallback(async () => {
    try {
      setCampaignInfoRequest();
      const campaignInfoResponse = await getCampaignService(getApiHost(), BEARERTOKEN, id);
      setCampaignInfoSuccess({ ...campaignInfoInitialState, ...campaignInfoResponse.data });
    } catch (error) {
      setCampaignInfoError(error.response.status);
    }
  }, [setCampaignInfoRequest, setCampaignInfoSuccess, setCampaignInfoError]);
};

export { useGetCampaign };
