import { Button, Dialog, Heading, Paragraph } from '@hexa-ui/components';

import { useIntl } from 'react-intl';
import {
  CustomDialogButtonActionsWrapper,
  CustomDialogConfirmButton,
} from './CustomDialogButton.styles';
import { CustomDialogButtonProps } from './types';

const { Root, Close } = Dialog;

const CustomDialogButton: React.FC<CustomDialogButtonProps> = ({
  triggerButtonVariant,
  headerText,
  triggerButtonText,
  confirmButtonText,
  cancelButtonText,
  bodyText,
  confirmButtonFn,
  confirmButtonActionType,
  reverseButtons,
  closeButton,
  disableEscapeKey,
  disablePointerDownOutside,
  disableInteractOutside,
  variant,
}) => {
  const { formatMessage } = useIntl();

  const disableAction = (e: Event, action: boolean): void => {
    action && e.preventDefault();
  };

  const confirmFn = async (): Promise<any> => {
    await confirmButtonFn();
  };

  return (
    <Root
      title={<Heading size="H2">{formatMessage({ id: headerText })}</Heading>}
      trigger={
        <Button
          variant={triggerButtonVariant}
          data-testid="dialog-trigger-button"
          css={{ width: '100%' }}
        >
          {formatMessage({ id: triggerButtonText })}
        </Button>
      }
      actions={
        <CustomDialogButtonActionsWrapper
          reverseButtons={reverseButtons}
          data-testid="dialog-action-buttons"
        >
          {cancelButtonText && (
            <Close>
              <Button size="medium" variant="secondary" data-testid="dialog-close-button">
                {formatMessage({ id: cancelButtonText })}
              </Button>
            </Close>
          )}
          <Close>
            <CustomDialogConfirmButton
              actionType={confirmButtonActionType}
              onClick={confirmFn}
              data-testid="dialog-confirm-button"
            >
              {formatMessage({ id: confirmButtonText })}
            </CustomDialogConfirmButton>
          </Close>
        </CustomDialogButtonActionsWrapper>
      }
      closeButton={closeButton}
      onEscapeKeyDown={(e) => {
        disableAction(e, disableEscapeKey);
      }}
      onPointerDownOutside={(e) => {
        disableAction(e, disablePointerDownOutside);
      }}
      onInteractOutside={(e) => {
        disableAction(e, disableInteractOutside);
      }}
      variant={variant}
      headerHeight={'$10'}
    >
      <Paragraph data-testid="dialog-description">{formatMessage({ id: bodyText })}</Paragraph>
    </Root>
  );
};

export default CustomDialogButton;
