import { Button, Card, Divider, Heading, Paragraph } from '@hexa-ui/components';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSaveCampaign } from '../../../../../../shared-components/CampaignFormAggregator/hooks/useSaveCampaign/useSaveCampaign';
import CustomDialogButton from '../../../../../../shared-components/CustomDialogButton/CustomDialogButton';
import { CampaignInfoContext } from '../../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { useRunAirdrop } from '../../../../hooks/useRunAirdrop/useRunAirdrop';
import Confirmation from '../../../ContentCard/Confirmation/Confirmation';
import {
  AirdropCardInfoContainer,
  AirdropCardInfoItem,
  AirdropCardWrapper,
} from '../../AirdropCard.styles';
import { confirmationConfig } from '../../config';
import { SnapshotBlockNumber } from '../../SnapshotBlockNumber/SnapshotBlockNumber';

type CreatedCardProps = {
  id: string;
  nftsAvailable: number;
  nftsHolders: number;
  snapshotDate: string;
  blockNumber: number;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
};

const CreatedCard: React.FC<CreatedCardProps> = ({
  id,
  nftsAvailable,
  nftsHolders,
  snapshotDate,
  blockNumber,
  setIsDeleting,
}) => {
  const { formatMessage } = useIntl();
  const { campaignInfo } = useContext(CampaignInfoContext);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const { data } = confirmationConfig();
  const toastService = useToast();
  const runAirdrop = useRunAirdrop();
  const campaignId = campaignInfo.id;
  const editCampaign = useSaveCampaign();

  const handleAirdrop = async () => {
    const hookResponse = await runAirdrop(campaignId, id);
    hookResponse?.success === false
      ? toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({
            id: 'campaignDashboardPage.airdrop.airdropCard.runAirdropError',
          }),
        })
      : toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({
            id: 'campaignDashboardPage.airdrop.airdropCard.runAirdropSuccess',
          }),
        });
  };

  const deleteAirdrop = async () => {
    setIsDeleting(true);
    const updatedAirdropList = campaignInfo.airdrop.filter((airdrop) => airdrop.id !== id);
    await editCampaign({ ...campaignInfo, airdrop: updatedAirdropList });
    setIsDeleting(false);
  };

  return (
    <Card
      data-testid="airdrop-created-card"
      border="medium"
      elevated="medium"
      css={{ height: isConfirmation ? '480px' : '360px' }}
    >
      <AirdropCardWrapper>
        <AirdropCardInfoContainer
          flexDirection="row"
          alignItems="center"
          justifyContent={'spaceBetween'}
          marginBottom="medium"
          css={{ minHeight: '100px' }}
        >
          <AirdropCardInfoItem
            width="half"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              type="H2"
              alignment="center"
              data-testid="airdrop-nfts-available"
              css={{ marginBottom: '$1' }}
            >
              {nftsAvailable}
            </Heading>
            <Paragraph alignment="center" size="small">
              {formatMessage({
                id: 'campaignDashboardPage.airdrop.airdropCard.nftsAvailable',
              })}
            </Paragraph>
          </AirdropCardInfoItem>

          <AirdropCardInfoItem
            width="half"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              type="H2"
              alignment="center"
              data-testid="airdrop-nfts-holders"
              css={{ marginBottom: '$1' }}
            >
              {nftsHolders}
            </Heading>
            <Paragraph alignment="center" size="small">
              {formatMessage({
                id: 'campaignDashboardPage.airdrop.airdropCard.nftsHolders',
              })}
            </Paragraph>
          </AirdropCardInfoItem>
        </AirdropCardInfoContainer>

        <AirdropCardInfoContainer marginBottom="medium">
          <Divider orientation="horizontal" />
        </AirdropCardInfoContainer>

        <SnapshotBlockNumber snapshotDate={snapshotDate} blockNumber={blockNumber} />

        <AirdropCardInfoContainer>
          {isConfirmation ? (
            <Confirmation
              props={data}
              setConfirmationStep={setIsConfirmation}
              confirmationAction={handleAirdrop}
            />
          ) : (
            <>
              <AirdropCardInfoItem width="half">
                <CustomDialogButton
                  triggerButtonVariant="secondary"
                  headerText="campaignDashboardPage.airdrop.airdropCard.deleteDialog.title"
                  triggerButtonText="campaignDashboardPage.airdrop.airdropCard.deleteAirdropButton"
                  confirmButtonText="campaignDashboardPage.airdrop.airdropCard.deleteDialog.confirmButton"
                  cancelButtonText="campaignDashboardPage.airdrop.airdropCard.deleteDialog.cancelButton"
                  bodyText="campaignDashboardPage.airdrop.airdropCard.deleteDialog.description"
                  confirmButtonFn={deleteAirdrop}
                  confirmButtonActionType="delete"
                  reverseButtons={true}
                />
              </AirdropCardInfoItem>
              <AirdropCardInfoItem width="half">
                <Button
                  variant="primary"
                  onClick={() => setIsConfirmation(true)}
                  data-testid="run-airdrop-button"
                  css={{ width: '100%' }}
                >
                  {formatMessage({
                    id: 'campaignDashboardPage.airdrop.airdropCard.runAirdropButton',
                  })}
                </Button>
              </AirdropCardInfoItem>
            </>
          )}
        </AirdropCardInfoContainer>
      </AirdropCardWrapper>
    </Card>
  );
};

export default CreatedCard;
