import { Grid } from '@hexa-ui/components';
import { useAppHeader } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
const { Container } = Grid;

import CampaignFormAggregator from '../../shared-components/CampaignFormAggregator/CampaignFormAggregator';
import { useGetCampaign } from '../../shared-components/CampaignFormAggregator/hooks/useGetCampaign/useGetCampaign';
import TabsComponent from '../../shared-components/Tabs/TabsComponent';
import { TabsProps } from '../../shared-components/Tabs/types';
import AirdropWrapper from './components/AirdropWrapper/AirdropWrapper';
import BasicSetupWrapper from './components/BasicSetupWrapper/BasicSetupWrapper';

const CampaignDashboardPage = () => {
  const { id, campaign } = useParams();
  const getCampaign = useGetCampaign(id);
  const [_, setAppHeaderConfig] = useAppHeader();
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(searchParams.get('currentTabId') || 'basic-setup');

  useEffect(() => {
    getCampaign();
  }, []);

  useEffect(() => {
    const currentTabId = searchParams.get('currentTabId');

    if (currentTabId) {
      setCurrentTab(currentTabId);
      searchParams.delete('currentTabId');
      setSearchParams(searchParams);
    }
  }, [location.search]);

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: campaign,
      breadcrumbConfig: {
        homePath: '/',
        items: [
          {
            label: formatMessage({ id: 'breadcrumb.homePage' }),
            path: '/nftcore',
            isCurrentPage: false,
          },
          {
            label: campaign,
            path: `/nftcore/campaign/${id}/dashboard/${campaign}`,
            isCurrentPage: true,
          },
        ],
      },
    });
  }, [setAppHeaderConfig]);

  const dashboardTabs: TabsProps = {
    currentTab,
    setCurrentTab,
    defaultTab: 'basic-setup',
    tabs: [
      {
        label: formatMessage({ id: 'campaignDashboardPage.tabs.basicSetup' }),
        id: 'basic-setup',
        content: () => <BasicSetupWrapper />,
      },
      {
        label: formatMessage({ id: 'campaignDashboardPage.tabs.campaignInfo' }),
        id: 'campaign-info',
        content: () => <CampaignFormAggregator />,
      },
      {
        label: formatMessage({ id: 'campaignDashboardPage.tabs.airdrop' }),
        id: 'airdrop',
        content: () => <AirdropWrapper />,
      },
    ],
  };

  return (
    <Container type="fluid" sidebar data-testid="campaign-dashboard-page">
      <TabsComponent {...dashboardTabs} />
    </Container>
  );
};

export default CampaignDashboardPage;
