import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Card, Heading, Paragraph } from '@hexa-ui/components';
import { Image } from '@hexa-ui/icons';

import Confirmation from '../Confirmation/Confirmation';
import Loading from '../Loading/Loading';

import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCampaignInfoContext } from '../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { formatDateToEnUs } from '../../../../../utils/formats/FormattedDate/formatted-date';
import { useUploadToIpfs } from '../../../../Home/hooks/useUploadToIpfs/useUploadToIpfs';
import { useRevealCollection } from '../../../hooks/useRevealCollection/useRevealCollection';
import { CardContainerStatus } from '../../CardContainer/types';
import { CardWrapper } from '../ContentCard.styles';
import { RevealCollectionProps } from '../types';
import {
  confirmationConfig,
  noCollectionConfirmationConfig,
  noIpfsCollectionConfirmationConfig,
} from './config';

const RevealCollection = ({ status }: RevealCollectionProps): JSX.Element => {
  const { campaignInfo } = useCampaignInfoContext();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const { data } = confirmationConfig();
  const noCollectionData = noCollectionConfirmationConfig();
  const noIpfsCollectionData = noIpfsCollectionConfirmationConfig();
  const { formatMessage } = useIntl();
  const toastService = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const uploadToIpfs = useUploadToIpfs();
  const revealCollection = useRevealCollection(campaignInfo.id);
  const hasLiveContract = campaignInfo?.liveContract?.hash;
  const hasCollection = campaignInfo?.liveCollectionStorage?.uploaded;
  const hasIpfsCollection = campaignInfo?.ipfsLiveCollection?.uploaded;
  const isDisabled = status === CardContainerStatus.DISABLED;

  const handleRevealCollection = async () => {
    try {
      setIsLoading(true);

      await revealCollection();

      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({
          id: 'campaignDashboardPage.contentCard.revealCollection.toast.success',
        }),
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({
          id: 'campaignDashboardPage.contentCard.revealCollection.toast.error',
        }),
      });
    }
  };

  const handleGoToCollection = () => {
    navigate(`${location.pathname}?currentTabId=campaign-info&currentStep=1`);
  };

  const handleUploadToIpfs = async () => {
    setIsLoading(true);

    const errorMessage = await uploadToIpfs(false);
    errorMessage
      ? toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({ id: errorMessage }),
        })
      : toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({
            id: 'campaignDashboardPage.contentCard.uploadToIpfs.toastMessage',
          }),
        });

    setIsLoading(false);
  };

  return (
    <CardWrapper data-testid="reveal-collection">
      <Card
        border="medium"
        elevated="small"
        className={isDisabled ? 'card disabled' : 'card'}
        data-testid="reveal-collection-card"
      >
        {!isLoading && !confirmationStep && (
          <>
            <Image size="xlarge" className="icon" />
            <Heading size="H3">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.revealCollection.header',
              })}
            </Heading>

            {campaignInfo.reveal?.revealed ? (
              <Paragraph className="longer" data-testid="revealed-collection">
                {formatMessage({
                  id: 'campaignDashboardPage.contentCard.revealCollection.secondState.description',
                })}
                {formatDateToEnUs(campaignInfo.reveal?.revealDate)}
              </Paragraph>
            ) : (
              <>
                {campaignInfo.lateReveal || (!campaignInfo.lateReveal && !hasLiveContract) ? (
                  <>
                    <Paragraph className="longer">
                      {formatMessage({
                        id: 'campaignDashboardPage.contentCard.revealCollection.firstState.description',
                      })}
                    </Paragraph>
                    <Button
                      variant="secondary"
                      onClick={() => setConfirmationStep(true)}
                      disabled={isDisabled}
                      data-testid="reveal-collection-button"
                    >
                      {formatMessage({
                        id: 'campaignDashboardPage.contentCard.revealCollection.firstState.button',
                      })}
                    </Button>
                  </>
                ) : (
                  <Paragraph className="longer" data-testid="no-reveal">
                    {formatMessage({
                      id: 'campaignDashboardPage.contentCard.revealCollection.noReveal.description',
                    })}
                  </Paragraph>
                )}
              </>
            )}
          </>
        )}

        {confirmationStep && (
          <>
            {!hasCollection ? (
              <Confirmation
                props={noCollectionData.data}
                setConfirmationStep={setConfirmationStep}
                confirmationAction={handleGoToCollection}
              />
            ) : (
              <>
                {!hasIpfsCollection ? (
                  <Confirmation
                    props={noIpfsCollectionData.data}
                    setConfirmationStep={setConfirmationStep}
                    confirmationAction={handleUploadToIpfs}
                  />
                ) : (
                  <Confirmation
                    props={data}
                    setConfirmationStep={setConfirmationStep}
                    confirmationAction={handleRevealCollection}
                  />
                )}
              </>
            )}
          </>
        )}

        {isLoading && (
          <Loading
            message={
              hasIpfsCollection
                ? formatMessage({
                    id: 'campaignDashboardPage.contentCard.revealCollection.loading.reveal',
                  })
                : formatMessage({
                    id: 'campaignDashboardPage.contentCard.revealCollection.loading.noIpfsCollection',
                  })
            }
          />
        )}
      </Card>
    </CardWrapper>
  );
};

export default RevealCollection;
