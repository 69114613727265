import { ICampaignInfoAction } from './campaignInfo.actions';
import { ICampaignInfoContext, ICampaignInfoReducerAction } from './campaignInfo.types';

export const campaignInfoReducer = (
  state: ICampaignInfoContext,
  action: ICampaignInfoReducerAction
): ICampaignInfoContext => {
  switch (action.type) {
    case ICampaignInfoAction.CAMPAIGN_INFO_REQUEST: {
      return {
        ...state,
        isLoading: true,
        campaignInfoError: null,
      };
    }
    case ICampaignInfoAction.CAMPAIGN_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        campaignInfo: action.payload,
        campaignInfoError: null,
      };
    }
    case ICampaignInfoAction.CAMPAIGN_INFO_ERROR: {
      return {
        ...state,
        isLoading: false,
        campaignInfoError: action.payload,
      };
    }
    case ICampaignInfoAction.CAMPAIGN_FORM_VALIDATION: {
      return {
        ...state,
        isCampaignFormValid: action.payload,
      };
    }
    case ICampaignInfoAction.CAMPAIGN_INFO_UPLOADING: {
      return {
        ...state,
        isUploading: action.payload,
      };
    }
    default:
      return state;
  }
};
