import { Button, Heading, Paragraph } from '@hexa-ui/components';
import {
  CenteredModal,
  CloseBtn,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
  ParagaphWrapper,
} from './Modal.styles';
interface IModal {
  setIsOpen: Function;
  buttonFunction: Function;
}

const Modal = ({ setIsOpen, buttonFunction }: IModal) => {
  const CloseButtonStyle = {
    backgroundColor: '#C71010',
    width: '155px',
  };

  const ConfirmButtonStyle = {
    color: 'black',
    backgroundColor: '#ffffff',
    width: '155px',
  };

  const confirmationFunction = () => {
    buttonFunction();
    setIsOpen(false);
  };

  return (
    <ModalWrapper data-testid="modal-wrapper" onClick={() => setIsOpen(false)}>
      <CenteredModal>
        <ModalContent data-testid="modal">
          <ModalHeader>
            <CloseBtn data-testid="close-modal-button" onClick={() => setIsOpen(false)}>
              x
            </CloseBtn>
            <div>
              <Heading size="H3">Confirm reveal activation?</Heading>
            </div>
          </ModalHeader>
          <ModalBody>
            <ParagaphWrapper>
              <Paragraph size="basis" weight="normal" font-family="Work-Sans">
                If you do, you can not reverse.
              </Paragraph>
            </ParagaphWrapper>
          </ModalBody>
          <ModalFooter>
            <Button
              data-testid="cancel-modal-button"
              style={CloseButtonStyle}
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="confirm-modal-button"
              css={{ marginLeft: '$4' }}
              variant="secondary"
              onClick={confirmationFunction}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </CenteredModal>
    </ModalWrapper>
  );
};

export { Modal };
