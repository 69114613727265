import { Button } from '@admin-portal-shared-components/button';
import { Grid } from '@material-ui/core';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { Steps } from '../../CampaignFormAggregator.types';
import { useSaveCampaign } from '../../hooks/useSaveCampaign/useSaveCampaign';
import CancelUploadDialog from '../CancelUploadDialog/CancelUploadDialog';

type ActionButtonsProps = {
  step: number;
  setStep: (step: number) => void;
  goToStep: number;
  setGoToStep: (step: number) => void;
  abortControllerRef?: MutableRefObject<AbortController>;
  isDashboard: boolean;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
  step,
  setStep,
  setGoToStep,
  goToStep,
  abortControllerRef,
  isDashboard,
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { isCampaignFormValid, isUploading, campaignInfo } = useCampaignInfoContext();
  const [isCancelUploadDialogOpen, setIsCancelUploadDialogOpen] = useState(false);
  const editCampaign = useSaveCampaign();
  const toastService = useToast();
  const savedCampaign = useRef(campaignInfo);
  const [disableSaveCampaign, setDisableCampaign] = useState(false);
  const [updateCampaignRef, setUpdateCampaignRef] = useState(false);

  useEffect(() => {
    if (updateCampaignRef) {
      savedCampaign.current = campaignInfo;
      setUpdateCampaignRef(false);
    }

    if (JSON.stringify(savedCampaign.current) === JSON.stringify(campaignInfo)) {
      setDisableCampaign(true);
    } else {
      setDisableCampaign(false);
    }
  }, [campaignInfo, savedCampaign.current, updateCampaignRef]);

  const shouldHideBackButton =
    isDashboard && (step === Steps.CAMPAIGN_STEP || step === Steps.OVERVIEW_STEP);

  const shouldDisableNextButton =
    step === Steps.COLLECTION_STEP &&
    campaignInfo.lateReveal &&
    !campaignInfo.placeholderCollectionStorage?.uploaded;

  const handleCancelUploadDialogOpen = () => {
    setIsCancelUploadDialogOpen(!isCancelUploadDialogOpen);
  };

  const decrementStep = (): void => {
    if (isUploading) {
      setGoToStep(Steps.CAMPAIGN_STEP);
      handleCancelUploadDialogOpen();
      return;
    }

    step === Steps.CAMPAIGN_STEP ? navigate('/nftcore') : setStep(--step);
  };

  const incrementStep = (): void => {
    if (isUploading) {
      setGoToStep(Steps.MINTING_STEP);
      handleCancelUploadDialogOpen();
      return;
    }

    step === Steps.OVERVIEW_STEP ? handleEditCampaign() : setStep(++step);
  };

  const handleEditCampaign = useCallback(async () => {
    const result = await editCampaign();

    if (result) {
      if (campaignInfo.status !== 'Draft') {
        setUpdateCampaignRef(true);
        toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({
            id: 'campaignFormAggregator.actionButtons.toast.editCampaign.success',
          }),
        });
      }

      navigate(`/nftcore/campaign/${campaignInfo.id}/dashboard/${campaignInfo.campaign}?new=true`);
    }
  }, [campaignInfo, savedCampaign.current]);

  const getButtonName = (status: string, formStep: Steps): string => {
    let name = formatMessage({
      id: 'campaignFormAggregator.campaignInfo.buttons.next',
    });
    if (formStep === Steps.OVERVIEW_STEP) {
      name = formatMessage({
        id: 'campaignFormAggregator.campaignInfo.buttons.saveCampaign',
      });
      if (status === 'Draft') {
        name = formatMessage({
          id: 'campaignFormAggregator.campaignInfo.buttons.createCampaign',
        });
      }
    }
    return name;
  };

  const nextButtonComponent = useMemo(() => {
    return (
      <Button
        variant="primary"
        data-testid="buttonRight"
        onClick={incrementStep}
        disabled={!isCampaignFormValid || shouldDisableNextButton}
      >
        {getButtonName(campaignInfo.status, step)}
      </Button>
    );
  }, [campaignInfo.status, isCampaignFormValid, shouldDisableNextButton, step, incrementStep]);

  const saveCampaignButtonComponent = useMemo(() => {
    return (
      <Button
        variant="primary"
        data-testid="save-campaign-button"
        onClick={incrementStep}
        disabled={isCampaignFormValid && disableSaveCampaign}
      >
        {getButtonName(campaignInfo.status, step)}
      </Button>
    );
  }, [campaignInfo.status, step, isCampaignFormValid, incrementStep, disableSaveCampaign]);

  return (
    <Grid item data-testid="actionButtons">
      <Grid container justifyContent={!shouldHideBackButton ? 'space-between' : 'flex-end'}>
        {!shouldHideBackButton && (
          <Button variant="secondary" data-testid="buttonLeft" onClick={decrementStep}>
            {step === Steps.CAMPAIGN_STEP ? (
              <>
                {formatMessage({
                  id: 'campaignFormAggregator.campaignInfo.buttons.cancel',
                })}
              </>
            ) : (
              <>
                {formatMessage({
                  id: 'campaignFormAggregator.campaignInfo.buttons.back',
                })}
              </>
            )}
          </Button>
        )}
        {step === Steps.OVERVIEW_STEP ? saveCampaignButtonComponent : nextButtonComponent}
      </Grid>
      <CancelUploadDialog
        isOpen={isCancelUploadDialogOpen}
        setIsOpen={handleCancelUploadDialogOpen}
        goToStep={goToStep}
        setGoToStep={setGoToStep}
        setStep={setStep}
        title="campaignFormAggregator.collectionForm.cancelUploadDialog.leavePageTitle"
        description="campaignFormAggregator.collectionForm.cancelUploadDialog.leavePageDescription"
        leftButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.leaveButton"
        rightButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.goBackButton"
        abortControllerRef={abortControllerRef}
      />
    </Grid>
  );
};

export default ActionButtons;
