import { useCallback, useContext } from 'react';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';

import getApiHost from '../../../../services/host/HostService';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { revealCollectionService } from '../../services/revealCollection/revealCollection';

const useRevealCollection = (id: string): Function => {
  const { campaignInfo, setCampaignInfoSuccess } = useContext(CampaignInfoContext);
  const { BEARERTOKEN } = useEnvContext();

  return useCallback(async (): Promise<void> => {
    try {
      const response = await revealCollectionService(getApiHost(), BEARERTOKEN, id);

      setCampaignInfoSuccess({ ...campaignInfo, reveal: response.data });
    } catch (error: any) {
      console.error(error.message);
      throw new Error('Could not reveal campaign');
    }
  }, [campaignInfo, setCampaignInfoSuccess]);
};

export { useRevealCollection };
