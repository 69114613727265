import { styled } from '@hexa-ui/theme';

export const UploadPlaceHolderButtonsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const UploadPlaceHolderParagraphsWrapper = styled('div', {
  display: 'block',
  marginBottom: '$8',
  gap: '$6',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  '& p': {
    margin: 0,
    '&:firstChild': {
      marginBottom: '$2',
    },
  },
});

export const UploadPlaceholderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
