import DateFnsUtils from '@date-io/date-fns';
import { Alert, Button, Divider, Input, Paragraph } from '@hexa-ui/components';
import { AlertOctagon } from '@hexa-ui/icons';
import { Grid, InputLabel, ThemeProvider } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { campaignDashboardFormTheme } from '../../../../../shared-components/Themes/campaignDashboardFormTheme';
import { CampaignInfoContext } from '../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { validateEthereumAddress } from '../../../../../utils/validators/validateEthereumAddress/validateEthereumAddress';
import { IGetHoldersResponse, useGetHolders } from '../../../hooks/useGetHolders/useGetHolders';
import useAirdrop from '../../AirdropContent/hooks/useAirdrop/useAirdrop';
import { AirdropFieldsWrapper } from './AirdropFields.styles';

const AirdropFields = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { campaignInfo, setCampaignInfoSuccess } = useContext(CampaignInfoContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getAirdropDraft, editAirdropDraft } = useAirdrop();
  const getHolders = useGetHolders();

  const [responseData, setResponseData] = useState<IGetHoldersResponse>();

  const airdropContractAddress = (e: React.ChangeEvent<any>) => {
    setCampaignInfoSuccess({
      ...campaignInfo,
      airdrop: [...editAirdropDraft('contractAddress', e.target.value)],
      inputErrors: {
        ...campaignInfo.inputErrors,
        airdropNftsHoldersContract: '',
      },
    });

    if (!validateEthereumAddress(getAirdropDraft()?.contractAddress)) {
      setCampaignInfoSuccess({
        ...campaignInfo,
        inputErrors: {
          ...campaignInfo.inputErrors,
          airdropNftsHoldersContract: formatMessage({
            id: 'campaignDashboardPage.airdrop.addresses.errors.invalidContract',
          }),
        },
      });
    }
  };

  /* istanbul ignore next */
  const airdropSnapshotDate = (value: any) => {
    setCampaignInfoSuccess({
      ...campaignInfo,
      airdrop: [...editAirdropDraft('snapshotDate', new Date(value).toISOString())],
      inputErrors: {
        ...campaignInfo.inputErrors,
        airdropNftsHoldersSnapshot: '',
      },
    });

    if (new Date(value).getTime() > Date.now()) {
      setCampaignInfoSuccess({
        ...campaignInfo,
        inputErrors: {
          ...campaignInfo.inputErrors,
          airdropNftsHoldersSnapshot: formatMessage({
            id: 'campaignDashboardPage.airdrop.addresses.errors.noFutureDateTime',
          }),
        },
      });
    }
  };

  const handleClick = async () => {
    setIsLoading(true);
    const hookResponse = await getHolders(getAirdropDraft()?.contractAddress);
    setResponseData(hookResponse);
    setIsLoading(false);

    const hasEnoughNft = Boolean(
      getAirdropDraft()?.nftsHolders?.length > getAirdropDraft()?.nftsAvailable * 0.8
    );

    const errorMessage = hasEnoughNft
      ? formatMessage({
          id: 'campaignDashboardPage.airdrop.addresses.errors.notEnoughNftAlertError',
        })
      : '';

    setCampaignInfoSuccess({
      ...campaignInfo,
      inputErrors: {
        ...campaignInfo.inputErrors,
        airdropAddresses: errorMessage,
      },
    });
  };

  return (
    <>
      <AirdropFieldsWrapper data-testid="airdrop-fields">
        <Input
          width="100%"
          label={formatMessage({
            id: 'campaignDashboardPage.airdrop.addresses.walletInputLabel',
          })}
          placeholder={formatMessage({
            id: 'campaignDashboardPage.airdrop.addresses.walletPlaceholder',
          })}
          alt={formatMessage({
            id: 'campaignDashboardPage.airdrop.addresses.walletAltText',
          })}
          hasError={!!campaignInfo.inputErrors.airdropNftsHoldersContract}
          errorText={campaignInfo.inputErrors.airdropNftsHoldersContract}
          value={getAirdropDraft()?.contractAddress}
          onChange={(value) => airdropContractAddress(value)}
          maxLength={42}
          data-testid="contract-address-input"
          css={{ marginBottom: '$4' }}
        />
        <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Grid item>
            <ThemeProvider theme={campaignDashboardFormTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <>
                  <InputLabel
                    shrink
                    style={{
                      color: `${
                        campaignInfo.inputErrors.airdropNftsHoldersSnapshot && 'rgb(201,32,29)'
                      }`,
                    }}
                  >
                    {formatMessage({
                      id: 'campaignDashboardPage.airdrop.addresses.snapshotDateLabel',
                    })}
                  </InputLabel>
                  {campaignInfo.inputErrors.airdropNftsHoldersSnapshot && (
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <AlertOctagon size="tiny" style={{ color: 'red' }} />
                        </Grid>
                        <Grid item>
                          <Paragraph size="small" colortype="error" css={{ padding: '0 0 $1 $2' }}>
                            {formatMessage({
                              id: 'campaignDashboardPage.airdrop.addresses.errors.noFutureDateTime',
                            })}
                          </Paragraph>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <KeyboardDateTimePicker
                    style={{ minWidth: '23%' }}
                    inputVariant="outlined"
                    variant="inline"
                    InputAdornmentProps={{ position: 'start' }}
                    invalidDateMessage={formatMessage({
                      id: 'campaignDashboardPage.airdrop.addresses.errors.invalidDate',
                    })}
                    format="MM/dd/yyyy HH:mm"
                    timeIcon
                    autoOk
                    disableFuture
                    disableToolbar
                    /* istanbul ignore next */
                    onChange={(date) => airdropSnapshotDate(date)}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={getAirdropDraft()?.snapshotDate}
                    error={!!campaignInfo.inputErrors.airdropNftsHoldersSnapshot}
                    data-testid="snapshot-date"
                  />
                </>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </Grid>
          <Button
            size="medium"
            variant="secondary"
            onClick={() => handleClick()}
            css={{ marginLeft: '$4' }}
            disabled={
              !validateEthereumAddress(getAirdropDraft()?.contractAddress) ||
              !!campaignInfo.inputErrors.airdropNftsHoldersSnapshot ||
              isLoading
            }
            isLoading={isLoading}
            data-testid="get-wallets-button"
          >
            {formatMessage({
              id: 'campaignDashboardPage.airdrop.addresses.buttonText',
            })}
          </Button>
        </Grid>
      </AirdropFieldsWrapper>
      <Divider orientation="horizontal" css={{ marginTop: '$8' }} />
      {responseData?.success === false && (
        <Alert
          type="error"
          message={formatMessage({
            id: 'campaignDashboardPage.airdrop.addresses.errors.genericAlertError',
          })}
          css={{ width: '100%', marginTop: '$6' }}
          data-testid="generic-error-alert"
        />
      )}
      {responseData?.success && getAirdropDraft()?.nftsHolders?.length === 0 && (
        <Alert
          type="error"
          message={formatMessage({
            id: 'campaignDashboardPage.airdrop.addresses.errors.noHoldersAlertError',
          })}
          css={{ width: '100%', marginTop: '$6' }}
          data-testid="no-holders-error-alert"
        />
      )}
      {responseData?.success && !!campaignInfo.inputErrors.airdropAddresses && (
        <Alert
          type="error"
          message={formatMessage({
            id: 'campaignDashboardPage.airdrop.addresses.errors.notEnoughNftAlertError',
          })}
          css={{ width: '100%', marginTop: '$6' }}
          data-testid="not-enough-error-alert"
        />
      )}
    </>
  );
};

export { AirdropFields };
