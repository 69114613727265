import { Button } from '@admin-portal-shared-components/button';
import { DocumentSigned } from '@admin-portal-shared-components/icons';
import { Grid, InputLabel } from '@material-ui/core';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { currencyFormatter } from '../../../../utils/formats/FormattedCurrency/formattedCurrency';
import { validateCurrency } from '../../../../utils/validators/currencyValidator/currencyValidator';
import { CustomCurrencyInputProps } from '../../../CustomCurrencyInput/types';
import CustomRadio from '../../../CustomRadio/CustomRadio';
import { RadioData } from '../../../CustomRadio/types';
import CustomSelect from '../../../CustomSelect/CustomSelect';
import { OptionsData } from '../../../CustomSelect/types';
import { useDownloadAllowlist } from '../../hooks/useDownloadAllowlist/useDownloadAllowlist';
import Allowlist from '../Allowlist/Allowlist';

const MintingForm = (): JSX.Element => {
  const { campaignInfo, setCampaignInfoSuccess } = useCampaignInfoContext();
  const { formatMessage } = useIntl();
  const [isAllowListOpen, setIsAllowListOpen] = useState(false);
  const [smartGas, setSmartGas] = useState<boolean>(null);
  const [gasFeeCap, setGasFeeCap] = useState(null);
  const downloadAllowlist = useDownloadAllowlist();

  const inputHandler = (e: React.ChangeEvent<any>) => {
    const gasFee = e.target.value.replace(/[^0-9.]+/g, '');

    const errorMessage = validateCurrency(gasFee);

    setGasFeeCap(gasFee);
    setCampaignInfoSuccess({
      ...campaignInfo,
      minting: {
        ...campaignInfo.minting,
        gasFeeCap: Number(gasFee),
      },
      inputErrors: {
        ...campaignInfo.inputErrors,
        gasFeeCap: errorMessage
          ? formatMessage({
              id: errorMessage,
            })
          : '',
      },
    });
  };

  const gasFeeValue = (): string => {
    if (gasFeeCap == null) {
      return campaignInfo.minting.gasFeeCap
        ? currencyFormatter.format(Number(campaignInfo.minting.gasFeeCap))
        : '';
    }
    return gasFeeCap;
  };

  const timingRadioOptions: Array<RadioData> = [
    {
      label: 'campaignFormAggregator.mintingForm.timing.preMint',
      description: 'campaignFormAggregator.mintingForm.timing.preMintDescription',
      value: 'Pre-minting',
      checked: false,
      disabled: true,
      hasInput: false,
    },
    {
      label: 'campaignFormAggregator.mintingForm.timing.postMint',
      description: 'campaignFormAggregator.mintingForm.timing.postMintDescription',
      value: 'Post-minting',
      checked: true,
      disabled: false,
      hasInput: false,
    },
  ];

  const currencyInputData: CustomCurrencyInputProps = {
    name: 'gasFeeCap',
    hasError: !!campaignInfo.inputErrors.gasFeeCap,
    disabled: smartGas,
    value: gasFeeValue,
    errorMessage: campaignInfo.inputErrors.gasFeeCap,
    valueHandler: setGasFeeCap,
    handler: inputHandler,
    testId: 'gas-fee-cap-input',
  };

  const optionsData: OptionsData[] = [
    {
      value: 'Ethereum',
      text: 'Ethereum',
    },
  ];

  const gasFeeRadioHandler = (label: string) => {
    const isSmartGasSelected = label === 'Smart gas';
    if (isSmartGasSelected !== smartGas) {
      setSmartGas(isSmartGasSelected);
      isSmartGasSelected && setGasFeeCap(null);
      setCampaignInfoSuccess({
        ...campaignInfo,
        minting: {
          ...campaignInfo.minting,
          smartGas: isSmartGasSelected,
          gasFeeCap: 0,
        },
        inputErrors: {
          ...campaignInfo.inputErrors,
          gasFeeCap: '',
        },
      });
    }
  };

  const isSmartGasEnabled = () => {
    return campaignInfo.minting.smartGas || Boolean(smartGas);
  };

  const gasFeeRadioOptions: Array<RadioData> = [
    {
      label: 'campaignFormAggregator.mintingForm.gasFee.smartGas',
      description: 'campaignFormAggregator.mintingForm.gasFee.smartGasDescription',
      value: 'Smart gas',
      checked: isSmartGasEnabled(),
      disabled: false,
      hasInput: false,
      handler: gasFeeRadioHandler,
      testId: 'smart-gas',
    },
    {
      label: 'campaignFormAggregator.mintingForm.gasFee.gasCap',
      description: 'campaignFormAggregator.mintingForm.gasFee.gasCapDescription',
      value: 'Gas cap',
      checked: !isSmartGasEnabled(),
      disabled: false,
      hasInput: true,
      inputData: currencyInputData,
      handler: gasFeeRadioHandler,
      testId: 'gas-cap',
    },
  ];

  const handleAllowList = () => {
    setIsAllowListOpen(!isAllowListOpen);
  };

  /* istanbul ignore next */
  const selectHandler = (value: string) => {
    setCampaignInfoSuccess({
      ...campaignInfo,
      minting: {
        ...campaignInfo.minting,
        blockChain: value,
      },
    });
  };

  const handleDownloadCsv = async () => {
    const csvFile = await downloadAllowlist(true);

    const csvFileUrl = window.URL.createObjectURL(csvFile);

    window.open(csvFileUrl, 'download');
  };

  return (
    <Grid container direction="column" spacing={3} data-testid="mintingForm">
      <Grid item style={{ marginBottom: '-18px' }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <InputLabel>
              {formatMessage({ id: 'campaignFormAggregator.mintingForm.timing.title' })}
            </InputLabel>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <CustomRadio name={'timing'} radioData={timingRadioOptions} />
      </Grid>

      <Grid item style={{ marginBottom: '-18px' }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <InputLabel>
              {formatMessage({ id: 'campaignFormAggregator.mintingForm.gasFee.title' })}
            </InputLabel>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <CustomRadio name={'gas-fee'} radioData={gasFeeRadioOptions} isCurrencyInput={true} />
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <CustomSelect
              optionsData={optionsData}
              label="Blockchain"
              name="blockchain"
              placeholder="Ethereum"
              translate={false}
              handler={selectHandler}
              disabled={true}
              testId="blockchainSelect"
            />
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', marginBottom: '4px' }}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <Button
                  onClick={handleAllowList}
                  variant="secondary"
                  data-testid="mintingAllowListButton"
                >
                  {campaignInfo.minting.allowList.length
                    ? formatMessage({
                        id: 'campaignFormAggregator.mintingForm.buttons.editMinters',
                      })
                    : formatMessage({
                        id: 'campaignFormAggregator.mintingForm.buttons.addMinters',
                      })}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="secondary"
                  leading
                  onClick={handleDownloadCsv}
                  icon={DocumentSigned}
                  style={{
                    display: campaignInfo.minting.allowList.length ? 'flex' : 'none',
                  }}
                  data-testid="mintingDownloadAllowListButton"
                >
                  {formatMessage({ id: 'campaignFormAggregator.mintingForm.buttons.download' })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Allowlist
        isOpen={isAllowListOpen}
        setIsOpen={handleAllowList}
        isMinters={true}
        title="campaignFormAggregator.mintingForm.allowlist.title"
      />
    </Grid>
  );
};

export default MintingForm;
