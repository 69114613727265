import { styled } from '@bees/vision-tokens';

export const AirdropDataCardWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: 'calc(100% - 2rem)',
  padding: '$8',
});

export const DataCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const DividerWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  minHeight: '100%',
});
