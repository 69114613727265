import { useContext } from 'react';
import { IAirDrop } from '../../../../../../models/campaignModel';
import { CampaignInfoContext } from '../../../../../../utils/context/campaignInfoContext/campaignInfo.context';

type useAirdropResult = {
  hasDraft: () => boolean;
  getAirdropDraft: () => IAirDrop;
  editAirdropDraft: (prop: string, value: any) => IAirDrop[];
};

const useAirdrop = (): useAirdropResult => {
  const { campaignInfo } = useContext(CampaignInfoContext);

  const hasDraft = (): boolean =>
    campaignInfo.airdrop.some((airdrop) => airdrop.status === 'DRAFT');

  const getAirdropDraft = (): IAirDrop =>
    campaignInfo.airdrop.find((airdrop) => airdrop.status === 'DRAFT');

  const editAirdropDraft = (prop: string, value: any): IAirDrop[] => {
    const array = campaignInfo.airdrop.map((airdrop) => {
      if (airdrop.status === 'DRAFT') {
        airdrop[prop] = value;
      }
      return airdrop;
    });
    return array;
  };

  return {
    hasDraft,
    getAirdropDraft,
    editAirdropDraft,
  };
};

export default useAirdrop;
