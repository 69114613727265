import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
const { Container } = Grid;

export const AirdropWarning = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '50vh',
});

export const ContainerWrapper = styled(Container, {
  paddingBottom: '$10',
  justifyContent: 'center',
});
