import { Grid } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import CardContainer from '../CardContainer/CardContainer';
import Claiming from '../ContentCard/Claiming/Claiming';
import PublishContract from '../ContentCard/PublishContract/PublishContract';
import RevealCollection from '../ContentCard/RevealCollection/RevealCollection';
import SetupShopify from '../ContentCard/SetupShopify/SetupShopify';
import TestContract from '../ContentCard/TestContract/TestContract';
import UploadArtToIpfs from '../ContentCard/UploadArtToIpfs/UploadArtToIpfs';
import DividerComponent from '../Divider/DividerComponent';
import { getCardProps } from './getCardProps';

const { Container, Item } = Grid;

const BasicSetupWrapper: React.FC = () => {
  const { campaignInfo } = useCampaignInfoContext();
  const { contractProps, marketplaceProps, collectionProps } = getCardProps(campaignInfo);
  const { formatMessage } = useIntl();

  return (
    <Container type="fluid" data-testid="basic-setup-wrapper">
      <Item lg={12} style={{ justifyContent: 'space-between' }}>
        <CardContainer
          title={formatMessage({
            id: 'campaignDashboardPage.contentCard.steps.contract.title',
          })}
          description={formatMessage({
            id: 'campaignDashboardPage.contentCard.steps.contract.description',
          })}
          position={1}
          status={contractProps.status}
        >
          <TestContract />
          <PublishContract />
        </CardContainer>
        <DividerComponent orientation="vertical" />
        <CardContainer
          title={formatMessage({
            id: 'campaignDashboardPage.contentCard.steps.marketplace.title',
          })}
          description={formatMessage({
            id: 'campaignDashboardPage.contentCard.steps.marketplace.description',
          })}
          position={2}
          status={marketplaceProps.status}
        >
          <SetupShopify {...marketplaceProps} />
          <Claiming {...marketplaceProps} />
        </CardContainer>
        <DividerComponent orientation="vertical" />
        <CardContainer
          title={formatMessage({
            id: 'campaignDashboardPage.contentCard.steps.collection.title',
          })}
          description={formatMessage({
            id: 'campaignDashboardPage.contentCard.steps.collection.description',
          })}
          position={3}
          status={collectionProps.status}
        >
          <UploadArtToIpfs {...collectionProps} />
          <RevealCollection {...collectionProps} />
        </CardContainer>
      </Item>
    </Container>
  );
};

export default BasicSetupWrapper;
