import { Divider } from '@admin-portal-shared-components/divider';
import { Button, Paragraph, Toggle } from '@hexa-ui/components';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { IStorageInfo } from '../../../../models/campaignModel';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { formatDateToEnUs } from '../../../../utils/formats/FormattedDate/formatted-date';
import { useAzureDownloadManagement } from '../../hooks/useAzureDownloadManagement/useAzureDownloadManagement';
import { AzureStorage } from '../../hooks/useUploadCollection/useUploadCollection';
import UploadPlaceholderModal from '../UploadPlaceholderModal/UploadPlaceholderModal';
import {
  ButtonContainer,
  ReplacePlacheholderContainer,
  RevealContainer,
  UploadPlaceholderContainer,
  Wrapper,
} from './RevealToggle.styles';

interface IProps {
  isDisabled: boolean;
  isChecked: boolean;
  handleToggle: () => void;
  collection?: IStorageInfo;
}

const RevealToggle = ({ handleToggle, isDisabled, isChecked, collection }: IProps): JSX.Element => {
  const { campaignInfo } = useCampaignInfoContext();
  const { formatMessage } = useIntl();
  const [modal, setModal] = useState(false);
  const azureDownload = useAzureDownloadManagement();
  const [isDownloading, setIsDownloading] = useState(false);

  const replacePlaceholder = (): JSX.Element => {
    return (
      <ReplacePlacheholderContainer data-testid="replace-placeholder">
        <Paragraph>
          {formatMessage({
            id: 'campaignFormAggregator.toggleComponent.replacePlaceHolder.totalFiles',
          })}
          {collection?.filesCount}
        </Paragraph>
        <Paragraph>
          {formatMessage({
            id: 'campaignFormAggregator.toggleComponent.replacePlaceHolder.uploaded',
          })}
          {formatDateToEnUs(collection?.uploadDate)}
        </Paragraph>
        <ButtonContainer>
          <Button
            disabled={isDisabled && campaignInfo?.liveContract?.hash ? true : false}
            onClick={changeModalState}
            variant="secondary"
            data-testid="replace-placeholder-button"
          >
            {formatMessage({
              id: 'campaignFormAggregator.toggleComponent.replacePlaceHolder.replaceButton',
            })}
          </Button>
          <Button
            variant="secondary"
            onClick={downloadPlaceholderCollection}
            isLoading={isDownloading}
            disabled={isDownloading}
            data-testid="replace-download-button"
          >
            {formatMessage({
              id: 'campaignFormAggregator.toggleComponent.replacePlaceHolder.DownloadButton',
            })}
          </Button>
        </ButtonContainer>
      </ReplacePlacheholderContainer>
    );
  };

  const changeModalState = () => {
    setModal((prev) => !prev);
  };

  const uploadPlaceholder = (): JSX.Element => {
    return (
      <UploadPlaceholderContainer data-testid="upload-placeholder">
        <Paragraph>
          {formatMessage({
            id: 'campaignFormAggregator.toggleComponent.uploadPlaceHolder.text',
          })}
        </Paragraph>
        <Button
          onClick={changeModalState}
          variant="secondary"
          data-testid="upload-placeholder-button"
        >
          {formatMessage({
            id: 'campaignFormAggregator.toggleComponent.uploadPlaceHolder.button',
          })}
        </Button>
      </UploadPlaceholderContainer>
    );
  };

  const downloadPlaceholderCollection = async () => {
    await azureDownload({
      azureStorage: AzureStorage.collection,
      campaignId: campaignInfo.id,
      reveal: true,
      setIsDownloading,
    });
  };

  return (
    <RevealContainer>
      <Wrapper>
        <Paragraph size="small" weight="medium">
          {formatMessage({
            id: 'campaignFormAggregator.toggleComponent.reveal',
          })}
        </Paragraph>
        <Toggle
          disabled={isDisabled}
          onCheckedChange={handleToggle}
          checked={isChecked}
          data-testid="reviewToggle"
        />
      </Wrapper>
      <Divider />
      {isChecked && collection?.uploaded && replacePlaceholder()}
      {isChecked && !collection?.uploaded && uploadPlaceholder()}
      <UploadPlaceholderModal
        data-testid="upload-placeholder-modal"
        handleModal={changeModalState}
        showModal={modal}
      />
    </RevealContainer>
  );
};
export { RevealToggle };
