import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Card, Heading, Paragraph, TextLink } from '@hexa-ui/components';
import { Checklist2 } from '@hexa-ui/icons';
import { OpenInNew } from '@material-ui/icons';
import { TypeToast, useToast } from 'admin-portal-shared-services';

import Confirmation from '../Confirmation/Confirmation';
import Loading from '../Loading/Loading';

import { useLocation, useNavigate } from 'react-router-dom';
import { useCampaignInfoContext } from '../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { formatDateToEnUs } from '../../../../../utils/formats/FormattedDate/formatted-date';
import { useUploadToIpfs } from '../../../../Home/hooks/useUploadToIpfs/useUploadToIpfs';
import { CardContainerStatus } from '../../CardContainer/types';
import { CardWrapper, LinksWrapper, TextLinkWrapper } from '../ContentCard.styles';
import { UploadToIpfsProps } from '../types';
import { confirmationAlternativeConfig, confirmationConfig } from './config';

const UploadArtToIpfs = ({ status }: UploadToIpfsProps): JSX.Element => {
  const { campaignInfo } = useCampaignInfoContext();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const { data } = confirmationConfig();
  const alternativeData = confirmationAlternativeConfig();
  const { formatMessage } = useIntl();
  const uploadToIpfs = useUploadToIpfs();
  const toastService = useToast();
  const hasCollection = campaignInfo?.ipfsLiveCollection?.uploaded;
  const isDisabled = status === CardContainerStatus.DISABLED;
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = async () => {
    setIsLoading(true);
    const errorMessage = await uploadToIpfs(false);
    errorMessage
      ? toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({ id: errorMessage }),
        })
      : toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({
            id: 'campaignDashboardPage.contentCard.uploadToIpfs.toastMessage',
          }),
        });
    setIsLoading(false);
  };

  const handleGoToCollection = () => {
    navigate(`${location.pathname}?currentTabId=campaign-info&currentStep=1`);
  };

  return (
    <CardWrapper data-testid="upload-to-ipfs">
      <Card border="medium" elevated="small" className={isDisabled ? 'card disabled' : 'card'}>
        {!isLoading && !confirmationStep && (
          <>
            <Checklist2 size="xlarge" className="icon" />
            <Heading size="H3">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.uploadToIpfs.header',
              })}
            </Heading>

            {!hasCollection ? (
              <>
                <Paragraph>
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.uploadToIpfs.firstState.description',
                  })}
                </Paragraph>
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => setConfirmationStep(true)}
                  data-testid="upload-button"
                  disabled={isDisabled}
                >
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.uploadToIpfs.firstState.button',
                  })}
                </Button>
              </>
            ) : (
              <>
                <Paragraph className="longer">
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.uploadToIpfs.secondState.description',
                  })}
                  {formatDateToEnUs(campaignInfo.ipfsLiveCollection?.uploadDate)}
                </Paragraph>
                <LinksWrapper data-testid="ipfs-links-wrapper">
                  <TextLinkWrapper>
                    <OpenInNew />
                    <TextLink href={campaignInfo.ipfsLiveCollection?.baseUri} target="_blank">
                      {formatMessage({
                        id: 'campaignDashboardPage.contentCard.uploadToIpfs.secondState.link',
                      })}
                    </TextLink>
                  </TextLinkWrapper>
                </LinksWrapper>
              </>
            )}
          </>
        )}

        {confirmationStep && (
          <>
            {(campaignInfo.lateReveal && !campaignInfo.placeholderCollectionStorage.uploaded) ||
            (!campaignInfo.lateReveal && !campaignInfo.liveCollectionStorage.uploaded) ? (
              <Confirmation
                props={alternativeData.data}
                setConfirmationStep={setConfirmationStep}
                confirmationAction={handleGoToCollection}
              />
            ) : (
              <Confirmation
                props={data}
                setConfirmationStep={setConfirmationStep}
                confirmationAction={handleClick}
              />
            )}
          </>
        )}

        {isLoading && (
          <Loading
            message={formatMessage({
              id: 'campaignDashboardPage.contentCard.uploadToIpfs.loadingMessage',
            })}
          />
        )}
      </Card>
    </CardWrapper>
  );
};

export default UploadArtToIpfs;
