import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

import { Button, Card, Heading, Paragraph, TextLink } from '@hexa-ui/components';
import { Clipboard } from '@hexa-ui/icons';
import { FileCopyOutlined, OpenInNew } from '@material-ui/icons';
import { TypeToast, useToast } from 'admin-portal-shared-services';

import Confirmation from '../Confirmation/Confirmation';
import Loading from '../Loading/Loading';

import { useCampaignInfoContext } from '../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { formattedDate } from '../../../../../utils/formats/FormattedDate/formatted-date';
import { useGenerateContract } from '../../../../Home/hooks/useGenerateContract/useGenerateContract';
import { CardWrapper, LinksWrapper, TextLinkWrapper } from '../ContentCard.styles';
import { confirmationConfig } from './config';

const TestContract = (): JSX.Element => {
  const { campaignInfo } = useCampaignInfoContext();
  const generateContract = useGenerateContract(campaignInfo.id, false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const { data } = confirmationConfig();
  const { formatMessage } = useIntl();
  const toastService = useToast();
  const handleClick = async () => {
    setIsLoading(true);
    const errorMessage = await generateContract();
    errorMessage
      ? toastService.notify({ type: TypeToast.ERROR, message: formatMessage({ id: errorMessage }) })
      : toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({
            id: 'campaignDashboardPage.contentCard.testContract.toastMessage',
          }),
        });
    setIsLoading(false);
  };

  return (
    <CardWrapper data-testid="test-contract">
      <Card border="medium" elevated="small" className="card">
        {!isLoading && !campaignInfo?.testNetContract?.isPublishing && (
          <>
            <Clipboard size="xlarge" className="icon" />
            <Heading size="H3">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.testContract.header',
              })}
            </Heading>
            {!confirmationStep && (
              <>
                {!campaignInfo?.testNetContract?.hash ? (
                  <Paragraph>
                    {!campaignInfo?.liveContract?.hash ? (
                      <>
                        {formatMessage({
                          id: 'campaignDashboardPage.contentCard.testContract.firstState.description',
                        })}
                      </>
                    ) : (
                      <>
                        {formatMessage({
                          id: 'campaignDashboardPage.contentCard.testContract.firstState.publishedContractDescription',
                        })}
                      </>
                    )}
                  </Paragraph>
                ) : (
                  <>
                    <Paragraph className="longer">
                      {formatMessage({
                        id: 'campaignDashboardPage.contentCard.testContract.secondState.description',
                      })}
                      {campaignInfo?.testNetContract?.publishedDate &&
                        formattedDate(
                          campaignInfo?.testNetContract?.publishedDate.toString(),
                          'day'
                        )}
                    </Paragraph>
                    <LinksWrapper data-testid="test-links-wrapper">
                      <CopyToClipboard text={campaignInfo && campaignInfo?.testNetContract?.hash}>
                        <TextLinkWrapper>
                          <FileCopyOutlined />
                          <TextLink>
                            {formatMessage({
                              id: 'campaignDashboardPage.contentCard.testContract.secondState.firstLink',
                            })}
                          </TextLink>
                        </TextLinkWrapper>
                      </CopyToClipboard>
                      <TextLinkWrapper>
                        <OpenInNew />
                        <TextLink
                          href={`https://goerli.etherscan.io/address/${campaignInfo?.testNetContract?.hash}`}
                          target="_blank"
                        >
                          {formatMessage({
                            id: 'campaignDashboardPage.contentCard.testContract.secondState.secondLink',
                          })}
                        </TextLink>
                      </TextLinkWrapper>
                    </LinksWrapper>
                  </>
                )}

                {!campaignInfo?.liveContract?.hash && (
                  <Button
                    variant="secondary"
                    size="medium"
                    onClick={() => setConfirmationStep(true)}
                    data-testid="test-button"
                  >
                    {formatMessage({
                      id: 'campaignDashboardPage.contentCard.testContract.firstState.button',
                    })}
                  </Button>
                )}
              </>
            )}
          </>
        )}

        {confirmationStep && (
          <Confirmation
            props={data}
            setConfirmationStep={setConfirmationStep}
            confirmationAction={handleClick}
          />
        )}

        {(campaignInfo?.testNetContract?.isPublishing || isLoading) && (
          <Loading
            message={formatMessage({
              id: 'campaignDashboardPage.contentCard.testContract.loadingMessage',
            })}
          />
        )}
      </Card>
    </CardWrapper>
  );
};

export default TestContract;
