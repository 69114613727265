import { Input } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import {
  currencyBlurHandler,
  currencyFocusHandler,
} from '../../utils/formats/FormattedCurrency/formattedCurrency';
import { CustomCurrencyInputProps } from './types';

const CustomCurrencyInput: React.FC<CustomCurrencyInputProps> = ({
  name,
  label,
  value,
  hasError,
  errorMessage,
  valueHandler,
  handler,
  autoFocus,
  testId,
  disabled,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Input
      width="100%"
      name={name}
      label={
        label
          ? formatMessage({
              id: label,
            })
          : ''
      }
      placeholder={'$'}
      value={value()}
      required
      onFocus={(e) => currencyFocusHandler(e, valueHandler)}
      onBlur={(e) => currencyBlurHandler(e, valueHandler)}
      onChange={(e) => handler(e)}
      autoFocus={autoFocus ?? false}
      disabled={disabled}
      hasError={hasError}
      errorText={errorMessage}
      data-testid={testId ?? `${name}-currency-input`}
    />
  );
};

export default CustomCurrencyInput;
