import { styled } from '@bees/vision-tokens';
import { Radio } from '@hexa-ui/components';

const { Root } = Radio;

export const RadioGroupWrapper = styled(Root, {
  display: 'flex',
  gap: '$6',
});

export const RadioWrapper = styled('div', {
  padding: '$4 $6',
  border: '1px solid',
  borderRadius: '$2',
  width: '100%',

  variants: {
    checked: {
      true: {
        borderColor: '$neutral100',
        '& div': {
          '& label': { color: '$neutral100' },
        },
      },
      false: {
        borderColor: '$neutral40',
        '& div': {
          '& label': { color: '$neutral50' },
        },
      },
    },
  },
});

export const RadioItemDescriptionWrapper = styled('span', {
  display: 'inline-block',
  margin: '0 0 $2 $8',
  paddingLeft: '$1',

  variants: {
    color: {
      true: { '& p': { color: '$neutral100' } },
      false: { '& p': { color: '$neutral50' } },
    },
    hasInput: {
      true: { '& p': { marginBottom: '$2' } },
    },
  },
});
