import { useIntl } from 'react-intl';

export const confirmationConfig = () => {
  const { formatMessage } = useIntl();

  return {
    data: {
      alert: formatMessage({ id: 'campaignDashboardPage.airdrop.airdropCard.confirmation.alert' }),
      description: formatMessage({
        id: 'campaignDashboardPage.airdrop.airdropCard.confirmation.description',
      }),
      primaryButton: formatMessage({
        id: 'campaignDashboardPage.airdrop.airdropCard.confirmation.primaryButton',
      }),
      secondaryButton: formatMessage({
        id: 'campaignDashboardPage.airdrop.airdropCard.confirmation.secondaryButton',
      }),
    },
  };
};
