import { useCallback, useContext } from 'react';
import getApiHost from '../../../../services/host/HostService';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { runAirdropService } from '../../services/runAirdrop/runAirdrop';

export interface IRunAirdropResponse {
  success: boolean;
  errors?: {
    status: number;
    message: { name: string };
  };
}

type Hook = () => (campaignId: string, airdropId: string) => Promise<IRunAirdropResponse>;

const useRunAirdrop: Hook = () => {
  const { campaignInfo, setCampaignInfoSuccess } = useContext(CampaignInfoContext);
  const { BEARERTOKEN } = useEnvContext();

  return useCallback(
    async (campaignId: string, airdropId: string) => {
      try {
        await runAirdropService(getApiHost(), BEARERTOKEN, campaignId, airdropId);

        const editedAirdropList = campaignInfo.airdrop.map((airdrop) => {
          if (airdrop.id === airdropId) {
            airdrop.status = 'ONGOING';
            airdrop.minted = 0;
          }
          return airdrop;
        });

        setCampaignInfoSuccess({ ...{ ...campaignInfo, airdrop: editedAirdropList } });

        return {
          success: true,
        };
      } catch (error: any) {
        return {
          success: false,
          errors: {
            status: error.response?.status,
            message: error.response?.data,
          },
        };
      }
    },
    [campaignInfo, setCampaignInfoSuccess, BEARERTOKEN]
  );
};

export { useRunAirdrop };
