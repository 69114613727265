import React, { useMemo } from 'react';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  BEARERTOKEN: string;
  OPTIMIZELY_KEY: string;
  STORAGE_ACCOUNT_NAME: string;
  CLAIM_APP_BASE_URL: string;
  DEFAULT_NETWORK: string;
};

const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  BEARERTOKEN: '',
  OPTIMIZELY_KEY: '',
  STORAGE_ACCOUNT_NAME: '',
  CLAIM_APP_BASE_URL: '',
  DEFAULT_NETWORK: '',
};

export const EnvContext = React.createContext(defaultInitialValue);

export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: EnvConfig; children: React.ReactNode }) => {
  const bearerToken = localStorage.getItem('authHeader');

  const envs = useMemo(() => {
    return { ...env, BEARERTOKEN: bearerToken };
  }, [env, bearerToken]);

  return <EnvContext.Provider value={envs}>{children}</EnvContext.Provider>;
};
