import { ICampaignForm } from '../../../../models/campaignFormModel';
import { formattedDate } from '../../../../utils/formats/FormattedDate/formatted-date';
import useAirdrop from '../AirdropContent/hooks/useAirdrop/useAirdrop';

export const config = (campaignInfo: ICampaignForm) => {
  const { getAirdropDraft } = useAirdrop();

  const collectionProps = {
    'campaignDashboardPage.airdrop.summary.airdropCollection.collection': campaignInfo.campaign,
    'campaignDashboardPage.airdrop.summary.airdropCollection.nftsAvailable':
      getAirdropDraft()?.nftsAvailable,
    'campaignDashboardPage.airdrop.summary.airdropCollection.nftsAvailableAfterDrop':
      getAirdropDraft()?.nftsAvailable - getAirdropDraft()?.nftsHolders.length,
  };

  const airdropProps = {
    'campaignDashboardPage.airdrop.summary.airdrop.wallets': getAirdropDraft()?.nftsHolders.length,
    'campaignDashboardPage.airdrop.summary.airdrop.holdersSnapshotDate': formattedDate(
      getAirdropDraft()?.snapshotDate,
      'day'
    ),
    'campaignDashboardPage.airdrop.summary.airdrop.blockNumber': getAirdropDraft()?.blockNumber,
  };

  return {
    collectionProps,
    airdropProps,
  };
};
