import { ICampaignListContext } from './campaignList.types';

export const initialState: ICampaignListContext = {
  isLoadingCampaignList: true,
  campaigns: [],
  campaignListError: null,
  setCampaignListRequest: () => undefined,
  setCampaignListSuccess: () => undefined,
  setCampaignListError: () => undefined,
};

export enum ICampaignListAction {
  CAMPAIGN_LIST_REQUEST = 'CAMPAIGN_LIST_REQUEST',
  CAMPAIGN_LIST_SUCCESS = 'CAMPAIGN_LIST_SUCCESS',
  CAMPAIGN_LIST_ERROR = 'CAMPAIGN_LIST_ERROR',
}
