import { Button, Grid } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { ActionButtonContainer } from './ActionButtons.styles';

const { Item } = Grid;

type ButtonProps = {
  text: string;
  onClick: () => void;
  isDisabled: boolean;
  hide: boolean;
};

export type ActionButtonsProps = {
  leftButton: ButtonProps;
  rightButton: ButtonProps;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({ leftButton, rightButton }) => {
  const { formatMessage } = useIntl();

  return (
    <ActionButtonContainer css={{ width: '100%', height: 'auto' }} data-testid="action-buttons">
      <Item>
        {!leftButton.hide && (
          <Button
            variant="secondary"
            disabled={leftButton.isDisabled}
            data-testid="buttonLeft"
            onClick={leftButton.onClick}
          >
            {formatMessage({ id: leftButton.text })}
          </Button>
        )}
      </Item>

      <Item>
        {!rightButton.hide && (
          <Button
            variant="primary"
            disabled={rightButton.isDisabled}
            data-testid="buttonRight"
            onClick={rightButton.onClick}
          >
            {formatMessage({ id: rightButton.text })}
          </Button>
        )}
      </Item>
    </ActionButtonContainer>
  );
};

export default ActionButtons;
