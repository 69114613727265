import { Divider, Heading, Paragraph } from '@hexa-ui/components';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { CampaignInfoContext } from '../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import useAirdrop from '../../AirdropContent/hooks/useAirdrop/useAirdrop';
import { default as CardDivider } from '../../Divider/DividerComponent';

import { AirdropDataCardWrapper, DataCard, DividerWrapper } from './AirdropDataCard.styles';

const AirdropDataCard = (): JSX.Element => {
  const { campaignInfoError } = useContext(CampaignInfoContext);
  const { formatMessage } = useIntl();
  const { getAirdropDraft } = useAirdrop();

  const hasEnoughNft = Boolean(
    getAirdropDraft()?.nftsHolders?.length <= getAirdropDraft()?.nftsAvailable * 0.8
  );

  if (hasEnoughNft && getAirdropDraft()?.nftsHolders?.length > 0 && !campaignInfoError) {
    return (
      <>
        <AirdropDataCardWrapper data-testid="airdrop-data-card">
          <DataCard data-testid="available-nfts">
            <Heading type="H2">{getAirdropDraft()?.nftsAvailable}</Heading>
            <Paragraph type="small">
              {formatMessage({
                id: 'campaignDashboardPage.airdrop.addresses.availableNftsCard',
              })}
            </Paragraph>
          </DataCard>
          <DividerWrapper>
            <CardDivider orientation="vertical" />
          </DividerWrapper>
          <DataCard data-testid="nft-holders">
            <Heading type="H2">{getAirdropDraft()?.nftsHolders.length}</Heading>
            <Paragraph type="small">
              {formatMessage({
                id: 'campaignDashboardPage.airdrop.addresses.nftsHolders',
              })}
            </Paragraph>
          </DataCard>
          <DividerWrapper>
            <CardDivider orientation="vertical" />
          </DividerWrapper>

          <DataCard data-testid="available-nfts-after-drop">
            <Heading type="H2">
              {getAirdropDraft()?.nftsAvailable - getAirdropDraft()?.nftsHolders.length}
            </Heading>
            <Paragraph type="small">
              {formatMessage({
                id: 'campaignDashboardPage.airdrop.addresses.nftsAvailableAfterDrop',
              })}
            </Paragraph>
          </DataCard>
        </AirdropDataCardWrapper>
        <Divider orientation="horizontal" />
      </>
    );
  }

  return null;
};

export { AirdropDataCard };
