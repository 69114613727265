import { Select } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { CustomSelectProps } from './types';

const { Root, Option } = Select;

const CustomSelect: React.FC<CustomSelectProps> = ({
  optionsData,
  name,
  label,
  placeholder,
  translate,
  disabled = false,
  handler,
  testId,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Root
      width="100%"
      name={name}
      label={translate ? formatMessage({ id: label }) : label}
      placeholder={translate ? formatMessage({ id: placeholder }) : placeholder}
      shape="square"
      data-testid={testId ?? `${name}-select`}
      onChange={handler}
      disabled={disabled}
    >
      {optionsData.map((option, index) => (
        <Option
          key={`${name}-option${index}`}
          value={option.value}
          disabled={option.disabled ?? false}
          data-testid={`${name}-option${index}`}
        >
          {translate ? formatMessage({ id: option.text }) : option.text}
        </Option>
      ))}
    </Root>
  );
};

export default CustomSelect;
