const uploadToIpfsErrorMapper = (errorName: string): string => {
  if (errorName.includes('Collection not found, upload it first'))
    return 'campaignDashboardPage.contentCard.uploadToIpfs.errors.collectionNotFound';
  if (errorName.includes('Placeholder collection not found, upload it first'))
    return 'campaignDashboardPage.contentCard.uploadToIpfs.errors.placeholderCollectionNotFound';
  if (errorName.includes('Contract should be deployed first'))
    return 'campaignDashboardPage.contentCard.uploadToIpfs.errors.contractNotPublished';
  return 'campaignDashboardPage.contentCard.uploadToIpfs.errors.ipfsError';
};

export { uploadToIpfsErrorMapper };
