import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    title: 'Test title',
    description: 'Test description',
    button: 'Test button',
    translate: 'Translate',
    missing: 'Only English option available',
  },
  homePage: {
    title: 'Manage campaigns',
    createNewCampaignButton: 'Create new campaign',
  },
  newCampaignPage: {
    title: 'Create new campaign',
  },
  campaignDashboardPage: {
    contentCard: {
      steps: {
        contract: {
          title: 'Contract',
          description: 'Set up the campaign smart contract',
        },
        marketplace: {
          title: 'Marketplace',
          description: 'Details on the campaign store',
        },
        collection: {
          title: 'Collection',
          description: 'View campaign assets details',
        },
      },
      testContract: {
        header: 'Test contract',
        firstState: {
          description: 'Set up a smart contract on the Goerli testnet.',
          publishedContractDescription: 'You cannot test your smart contract anymore.',
          button: 'Test',
        },
        secondState: {
          description: 'Published on: ',
          firstLink: 'Copy contract hash to clipboard',
          secondLink: 'Open in Etherscan',
        },
        confirmation: {
          description: 'Set up a new version of the smart contract on the Goerli testnet?',
          primaryButton: 'Cancel',
          secondaryButton: 'Test',
        },
        loadingMessage: 'Deploying contract to testnet. This may take some time.',
        toastMessage: 'Test contract deployed successfully!',
      },
      publishContract: {
        header: 'Publish contract',
        firstState: {
          description: 'Publish the campaign smart contract on the Ethereum mainnet.',
          button: 'Publish',
        },
        secondState: {
          description: 'Published on: ',
          firstLink: 'Copy contract hash to clipboard',
          secondLink: 'Open in Etherscan',
        },
        confirmation: {
          alert: 'Publishing cannot be reversed.',
          alertWithoutTest: 'Contract has not been tested yet.',
          description: 'Publish smart contract to mainnet?',
          primaryButton: 'Cancel',
          secondaryButton: 'Publish',
        },
        confirmationAlternative: {
          alert: 'You do not have a collection yet.',
          description: 'Upload one or enable the reveal to proceed.',
          primaryButton: 'Go back',
          secondaryButton: 'Go to collection',
        },
        loadingMessage: 'Deploying contract to mainnet. This may take some time.',
        toastMessage: 'Main contract deployed successfully!',
        errors: {
          contractDeployFailed: 'Contract deploy failed. Try again later.',
          liveContractAlreadyPublished: 'Live contract already published',
          alreadyDeployingContract:
            'This campaign is already deploying a contract. Wait a few seconds and try again.',
        },
      },
      uploadToIpfs: {
        header: 'Art to IPFS',
        firstState: {
          description: 'Upload the campaign NFT collection to IPFS',
          button: 'Upload art',
        },
        secondState: {
          description: 'Uploaded on: ',
          link: 'Show collection',
        },
        confirmation: {
          alert: 'Upload cannot be reversed.',
          description: 'Upload NFT collection to IPFS?',
          primaryButton: 'Cancel',
          secondaryButton: 'Upload',
        },
        confirmationAlternative: {
          alert: 'No collection files in this campaign.',
          description: 'To upload to IPFS, you must first add collection files to this campaign.',
          primaryButton: 'Go back',
          secondaryButton: 'Go to collection',
        },
        loadingMessage: 'Uploading collection to IPFS. This may take some time.',
        toastMessage: 'Collection uploaded to IPFS successfully!',
        errors: {
          ipfsError: 'Error uploading collection to IPFS. Try again.',
          collectionNotFound: 'Collection not found. Upload it first.',
          placeholderCollectionNotFound: 'Placeholder collection not found. Upload it first.',
          contractNotPublished: 'Contract should be published first.',
        },
      },
      claiming: {
        header: 'Claiming page',
        description: 'Preview the single page website for the NFT Collection claims.',
        button: 'Show page',
      },
      revealCollection: {
        header: 'Reveal collection',
        firstState: {
          description: 'Replace the placeholder files with the collection files',
          button: 'Reveal now',
        },
        secondState: {
          description: 'Made public on: ',
        },
        noReveal: {
          description:
            'You cannot reveal your collection anymore due to the campaign current status.',
        },
        confirmation: {
          alert: 'Reveal process cannot be reversed.',
          description: 'This collection is hidden, reveal it now?',
          primaryButton: 'Cancel',
          secondaryButton: 'Reveal',
        },
        noCollectionConfirmation: {
          alert: 'No collection files in this campaign.',
          description:
            'To reveal the collection, you must first add collection files to this campaign.',
          primaryButton: 'Cancel',
          secondaryButton: 'Go to collection',
        },
        noIpfsCollectionConfirmation: {
          alert: 'Upload to IPFS is pending.',
          description: 'To reveal this collection, you must upload to IPFS first. Upload now?',
          primaryButton: 'Cancel',
          secondaryButton: 'Upload',
        },
        loading: {
          reveal: 'Revealing collection. This may take some time.',
          noIpfsCollection: 'Uploading collection to IPFS. This may take some time.',
        },
        toast: {
          success: 'Collection revealed successfully!',
          error: 'Could not reveal the collection. Try again.',
        },
      },
      setupShopify: {
        header: 'Set up Shopify',
        firstState: {
          description: 'Send the campaign data for marketplace configuration.',
          button: 'Send data',
        },
        secondState: {
          description: 'Sent on: ',
          link: 'Open the online store',
        },
        confirmation: {
          description: 'Send the collection information and price per NFT to Shopify?',
          primaryButton: 'Cancel',
          secondaryButton: 'Send',
        },
        loadingMessage: 'Sending information to Shopify, this may take some time.',
        toastMessage: 'Information sent to Shopify successfully!',
        errors: {
          dataAlreadySent: 'Data already sent to Shopify.',
          collectionNotFound: 'Collection not found. Upload it first.',
          contractNotPublished: 'Contract should be published first.',
          generic: 'Something went wrong. Try again.',
        },
      },
    },
    airdrop: {
      actionButtons: {
        leftButtonText: {
          back: 'Go back',
        },
        rightButtonText: {
          next: 'Next',
          create: 'Create airdrop',
        },
      },
      recipients: {
        list: {
          title: 'List',
          nftHolders: {
            label: 'NFT holders',
            description: 'Extract a list of wallets from a campaign',
          },
          uploadList: {
            label: 'Upload list',
            description: 'Add a file with the wallet addresses',
          },
        },
      },
      addresses: {
        walletInputLabel: 'NFT collection',
        walletPlaceholder: 'Smart contract hash to extract the list of holders',
        walletAltText: 'Contract hash input to extract list of holders',
        snapshotDateLabel: 'Snapshot date',
        availableNftsCard: 'Buds 4 Life available NFTS',
        nftsHolders: 'Wallet addresses',
        nftsAvailableAfterDrop: 'NFTs available after airdrop',
        buttonText: 'Get wallets',
        errors: {
          invalidDate: 'Invalid date.',
          noFutureDateTime: 'Should be earlier than the current time.',
          invalidContract: 'You need a valid contract hash to extract the list of holders.',
          genericAlertError: 'The loading of the wallets list has failed. Try again.',
          noHoldersAlertError: 'No holders found for this NFT collection.',
          notEnoughNftAlertError: 'You do not have enough available NFTs to perform this airdrop.',
        },
      },
      summary: {
        airdropCollection: {
          title: 'Collection',
          collection: 'ABI collection',
          nftsAvailable: 'NFTs available',
          nftsAvailableAfterDrop: 'NFTs available after airdrop',
        },
        airdrop: {
          title: 'Airdrop',
          wallets: 'Wallet addresses',
          holdersSnapshotDate: 'Holders snapshot date',
          blockNumber: 'Snapshot block number',
        },
        error: {
          failCreateAirdrop: 'Could not create the airdrop. Try again.',
        },
      },
      warnings: {
        noShopifySetup: 'The campaign data must be sent to Shopify first.',
        noIpfsCollection: 'The collection files must be uploaded to IPFS first.',
      },
      airdropContent: {
        steps: {
          recipients: {
            label: 'Recipients',
            description: 'Extract or upload a list of wallets',
          },
          addresses: {
            label: 'Addresses',
            description: 'List wallets that will receive an airdrop',
          },
          summary: {
            label: 'Summary',
            description: 'Review and confirm',
          },
        },
      },
      airdropCard: {
        nftsAvailable: 'NFTs available',
        nftsHolders: 'Wallet addresses',
        nftsAirdropped: 'NFTs airdropped',
        snapshotDate: 'Holders snapshot date',
        blockNumber: 'Snapshot block number',
        deleteAirdropButton: 'Delete',
        runAirdropButton: 'Run airdrop',
        sentOn: 'Sent on',
        leadtime: 'Airdrop leadtime',
        airdropProgress: 'Airdrop progress',
        runAirdropError: 'Airdrop could not run successfully',
        runAirdropSuccess: 'Airdrop started successfully!',
        confirmation: {
          alert: 'Airdrop cannot be reversed.',
          description: 'Send NFTs to holders wallets?',
          primaryButton: 'Cancel',
          secondaryButton: 'Run Airdrop',
        },
        deleteDialog: {
          title: 'Delete airdrop?',
          description: 'Id you do, you will lose this aidrop configuration.',
          confirmButton: 'Delete airdrop',
          cancelButton: 'Keep airdrop',
        },
      },
    },
    tabs: {
      campaignInfo: 'Campaign Info',
      basicSetup: 'Basic Setup',
      airdrop: 'Airdrop',
    },
  },
  errorPage: {
    404: {
      buttonText: 'Home',
      headerText: 'Mmm… Looks like this page is gone',
      subheaderText: 'It looks like this page wasn’t found or doesn’t exist.',
    },
    500: {
      headerText: 'Ooops! Something went wrong',
    },
    503: {
      buttonText: 'Try again',
      headerText: 'Hm... Service unavailable!',
      subheaderText:
        'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
      infoText: 'If the issue persists, you can report the problem.',
    },
  },
  breadcrumb: {
    homePage: 'Manage campaigns',
    createCampaignPage: 'New Campaign',
  },
  campaignFormAggregator: {
    alert: {
      contractAlreadyPublished:
        'Since this campaign already has a contract published on Ethereum Mainnet, it will not be possible to edit some of its fields.',
    },
    campaignInfo: {
      labels: {
        brand: 'Brand',
        url: 'Brand NFT website',
        name: 'Title',
        description: 'Description',
        symbol: 'Symbol',
        launchDate: 'Launch date',
        nftBasePrice: 'Price per NFT',
        optional: 'Optional',
        presale: {
          title: 'Presale campaign',
          basePrice: 'Base price',
          startDate: 'Presale start date',
          endDate: 'Presale end date',
        },
      },
      placeholders: {
        brand: 'Select a value',
        url: 'https://',
        name: 'Campaign name',
        description: 'Explain the campaign',
        symbol: 'e.g. ABI',
      },
      allowList: {
        title: 'Presale Allowlist',
        description: 'Drop CSV file here to start uploading or',
        loadingMsg: 'Uploading file',
        walletCount: 'Number of wallets',
        lastUploaded: 'Uploaded',
        editMsg: 'Edit the current file by downloading and replacing it.',
        buttons: {
          download: 'Download',
          replace: 'Replace',
          browseFiles: 'Browse file',
        },
      },
      buttons: {
        addAllowList: 'Add allowlist',
        editAllowList: 'Edit allowlist',
        downloadAllowList: 'Download allowlist',
        back: 'Back',
        cancel: 'Cancel',
        next: 'Next',
        createCampaign: 'Create Campaign',
        saveCampaign: 'Save Campaign',
      },
      errors: {
        requiredField: 'Required field',
        titleRequired: 'You need a title to create a campaign.',
        symbolRequired: 'Your campaign needs a symbol to be created.',
        valueGreaterThanZero: 'The value must be greater than 0.',
        invalidValue: 'Invalid value.',
        invalidDate: 'Invalid date.',
        launchDateMinDate: 'Should be later than the end date of your presale.',
        endDateMinDate: 'Should be later than the start date of your presale.',
        noPastDates: 'Your date should not be in the past.',
      },
    },
    collectionForm: {
      title: 'Collection files',
      description: 'Add metadata files (.json)',
      upload: {
        title: 'Uploading files',
        description: 'This can take some time.',
        cancelButton: 'Cancel upload',
      },
      cancelUploadDialog: {
        cancelUploadTitle: 'Cancel upload?',
        leavePageTitle: 'Leave page?',
        cancelUploadDescription: 'If you do, you will lose the current collection files.',
        leavePageDescription: 'You will cancel the current collection upload',
        cancelButton: 'Cancel upload',
        keepUploadButton: 'Keep uploading',
        leaveButton: 'Yes, leave page',
        goBackButton: 'No, go back',
      },
      totalFiles: 'Total number of files',
      totalNumberOfNfts: 'Total number of NFTs',
      lastUploaded: 'Uploaded',
      buttons: {
        download: 'Download collection',
        replace: 'Replace files',
        browseFiles: 'Browse files',
      },
      toast: {
        cancelledMessage: 'Collection upload cancelled',
        uploadFailed: 'The upload has failed. Try again.',
        invalidJsonFileName: 'Invalid JSON file name',
        downloadFailed: 'Failed to download collection. Try again.',
        invalidFilesNumber: 'Number of files uploaded are different from the previous ones',
      },
      revealDialog: {
        title: 'Reveal Metadata',
        description: 'Drop files here to start uploading or',
        loadingMsg: 'Uploading file',
        filesNumber: 'Total number of files',
        lastUploaded: 'Uploaded',
        editMsg: 'Edit the current file by downloading and replacing it.',
        buttons: {
          ok: 'Ok',
          replace: 'Replace',
          browseFiles: 'Browse file',
        },
      },
    },
    mintingForm: {
      timing: {
        title: 'Timing',
        preMint: 'Pre-minting',
        preMintDescription: 'Mint the collection before the launch date.',
        postMint: 'Post-minting',
        postMintDescription: 'Mint the NFT to the buyer’s wallet.',
      },
      gasFee: {
        title: 'Gas Fee',
        smartGas: 'Smart Gas',
        smartGasDescription: 'Automatically identify the ideal price for minting.',
        gasCap: 'Gas Cap',
        gasCapDescription: 'Manually set a maximum price for minting.',
      },
      blockchain: {
        title: 'Blockchain',
        ethereum: 'Ethereum',
      },
      gasFeeCap: {
        title: 'Gas fee cap (not in contract)',
      },
      buttons: {
        addMinters: 'Add minters',
        editMinters: 'Edit minters',
        download: 'Download minters list',
      },
      allowlist: {
        title: 'Minters allowlist',
      },
      toast: {
        csvFormatNotValid: 'CSV is not formatted as expected.',
        invalidWallet: 'CSV contains invalid wallet.',
        csvUploadError: 'The upload has failed. Try again.',
      },
    },
    reviewForm: {
      edit: 'Edit',
      campaignCard: {
        title: 'Campaign',
        brand: 'Brand',
        campaignTitle: 'Title',
        description: 'Description',
        brandURL: 'Brand URL',
        pricePerNft: 'Price per NFT',
        symbol: 'Symbol',
        launchDate: 'Launch date',
        preSale: {
          enabled: 'Presale campaign actived',
          nftBasePrice: 'Presale base price',
          startDate: 'Presale start date',
          endDate: 'Presale end date',
          folderName: 'Presale folder name',
          uploaded: 'Presale uploaded',
        },
      },
      collectionCard: {
        title: 'Collection',
        empty: 'You do not have a collection or reveal configured, you can do it later.',
        lateReveal: 'Reveal activated',
        placeholderCount: 'Total number of placeholder files',
        collectionCount: 'Total number of collection files',
        uploadedPlaceholder: 'Uploaded',
        uploadedCollection: 'Uploaded',
      },
      mintingCard: {
        title: 'Minting',
        timing: 'Timing',
        blockchain: 'Blockchain',
        gasFee: 'Gas fee',
        numberOfWallets: 'Number of wallets',
        uploaded: 'Uploaded',
      },
    },
    stepper: {
      firstStep: {
        label: 'Campaign',
        description: 'Main info about the campaign',
      },
      secondStep: {
        label: 'Collection',
        description: 'Details about the NFT artwork',
      },
      thirdStep: {
        label: 'Minting',
        description: 'Contract minting settings',
      },
      fourthStep: {
        label: 'Review',
        description: 'Summary of the campaign',
      },
    },
    actionButtons: {
      toast: {
        editCampaign: {
          success: 'Your changes were successfully saved',
        },
      },
    },
    toggleComponent: {
      reveal: 'Reveal',
      uploadPlaceHolder: {
        text: 'Upload placeholder files (.json) which will be used before collection reveal.',
        button: 'Add metadata',
      },
      replacePlaceHolder: {
        totalFiles: 'Total number of files: ',
        uploaded: 'Uploaded on: ',
        replaceButton: 'Replace files',
        DownloadButton: 'Download metadata',
      },
    },
  },
};

export default enUS;
