import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const { Item } = Grid;

export const AirdropCardWrapper = styled(Item, {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '320px',
  padding: '$6',
});

export const AirdropCardInfoContainer = styled(Item, {
  display: 'flex',

  variants: {
    flexDirection: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
    },
    justifyContent: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      spaceBetween: {
        justifyContent: 'space-between',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    alignItems: {
      start: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
    },
    marginBottom: {
      small: {
        marginBottom: '$2',
      },
      medium: {
        marginBottom: '$4',
      },
      large: {
        marginBottom: '$8',
      },
    },
  },
});

export const AirdropCardInfoItem = styled(Item, {
  display: 'flex',

  variants: {
    width: {
      full: {
        width: '100%',
      },
      half: {
        width: '50%',
      },
      auto: {
        width: 'auto',
      },
    },
    flexDirection: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
    },
    justifyContent: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      spaceBetween: {
        justifyContent: 'space-between',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    alignItems: {
      start: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
    },
  },
});
