import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const { Item, Container } = Grid;

export const RecipientsContainer = styled(Container, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const RecipientsItemContainer = styled(Container, {
  display: 'flex',
  justifyContent: 'space-between',

  variants: {
    direction: {
      column: {
        flexDirection: 'column',
        alignItems: 'space-between',
      },
      row: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    marginBottom: {
      true: {
        marginBottom: '$6 !important',
      },
      false: {
        marginBottom: '0',
      },
    },
  },
});

export const RecipientsItem = styled(Item, {
  width: '100%',
  height: 'auto',
  variants: {
    marginBottom: {
      true: {
        display: 'block',
        marginBottom: '$2',
      },
      false: {
        display: 'block',
        marginBottom: '0',
      },
    },
  },
});
