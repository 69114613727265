import { InputLabel } from '@material-ui/core';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import CustomRadio from '../../../../shared-components/CustomRadio/CustomRadio';
import { RadioData } from '../../../../shared-components/CustomRadio/types';
import CustomSelect from '../../../../shared-components/CustomSelect/CustomSelect';
import { OptionsData } from '../../../../shared-components/CustomSelect/types';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import useAirdrop from '../AirdropContent/hooks/useAirdrop/useAirdrop';
import { RecipientsContainer, RecipientsItem, RecipientsItemContainer } from './Recipients.styles';

const nftHoldersRadioOptions: Array<RadioData> = [
  {
    label: 'campaignDashboardPage.airdrop.recipients.list.nftHolders.label',
    description: 'campaignDashboardPage.airdrop.recipients.list.nftHolders.description',
    value: 'Nft-holders',
    disabled: false,
    hasInput: false,
    testId: 'nftHolders',
    checked: true,
  },
  {
    label: 'campaignDashboardPage.airdrop.recipients.list.uploadList.label',
    description: 'campaignDashboardPage.airdrop.recipients.list.uploadList.description',
    value: 'Upload-list',
    disabled: true,
    hasInput: false,
    testId: 'uploadList',
    checked: false,
  },
];

const optionsData: OptionsData[] = [
  {
    value: 'Ethereum',
    text: 'Ethereum',
  },
];

const Recipients = () => {
  const { campaignInfo, setCampaignInfoSuccess } = useCampaignInfoContext();
  const { formatMessage } = useIntl();
  const { hasDraft, editAirdropDraft } = useAirdrop();

  useEffect(() => {
    if (!hasDraft()) {
      setCampaignInfoSuccess({
        ...campaignInfo,
        airdrop: [
          ...campaignInfo.airdrop,
          {
            status: 'DRAFT',
            blockChain: 'Ethereum',
            nftsHolders: [],
            snapshotDate: new Date().toISOString(),
          },
        ],
      });
    }
  }, []);

  /* istanbul ignore next */
  const selectHandler = (value: string) => {
    setCampaignInfoSuccess({
      ...campaignInfo,
      airdrop: [...editAirdropDraft('blockChain', value)],
    });
  };

  return (
    <RecipientsContainer css={{ width: '100%', height: '100%' }} data-testid="recipients">
      <RecipientsItem marginBottom={false} css={{ width: '100%', padding: '0' }}>
        <RecipientsItemContainer
          css={{ width: '100%', height: 'auto', padding: '0' }}
          marginBottom={true}
          direction="row"
        >
          <RecipientsItem marginBottom={true}>
            <InputLabel>
              {formatMessage({ id: 'campaignDashboardPage.airdrop.recipients.list.title' })}
            </InputLabel>
          </RecipientsItem>
          <RecipientsItem marginBottom={false}>
            <CustomRadio name={'nft-holders'} radioData={nftHoldersRadioOptions} />
          </RecipientsItem>
        </RecipientsItemContainer>
      </RecipientsItem>

      <RecipientsItemContainer
        css={{ width: '100%', height: 'auto' }}
        marginBottom={false}
        direction="row"
      >
        <RecipientsItem marginBottom={false}>
          <CustomSelect
            optionsData={optionsData}
            label="Blockchain"
            name="blockchain"
            placeholder="Ethereum"
            translate={false}
            handler={selectHandler}
            disabled={true}
            testId="blockchain-select"
          />
        </RecipientsItem>
      </RecipientsItemContainer>
    </RecipientsContainer>
  );
};

export default Recipients;
