import { Card, Divider, Heading, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { formatDateToEnUs } from '../../../../../../utils/formats/FormattedDate/formatted-date';
import {
  AirdropCardInfoContainer,
  AirdropCardInfoItem,
  AirdropCardWrapper,
} from '../../AirdropCard.styles';
import { SnapshotBlockNumber } from '../../SnapshotBlockNumber/SnapshotBlockNumber';

type DoneCardProps = {
  snapshotDate: string;
  blockNumber: number;
  minted: number;
  sentOn: number;
  leadTime: number;
};

const DoneCard: React.FC<DoneCardProps> = ({
  snapshotDate,
  blockNumber,
  minted,
  sentOn,
  leadTime,
}) => {
  const { formatMessage } = useIntl();

  const leadTimeMinutes = Math.floor((leadTime / (1000 * 60)) % 60); // milliseconds in minutes, applied remainder
  const leadTimeHours = Math.floor(leadTime / (1000 * 60 * 60)); // milliseconds in hours

  return (
    <Card
      data-testid="airdrop-done-card"
      border="medium"
      elevated="medium"
      css={{ height: '360px' }}
    >
      <AirdropCardWrapper>
        <AirdropCardInfoContainer
          flexDirection="row"
          alignItems="center"
          justifyContent={'center'}
          marginBottom="medium"
          css={{ minHeight: '100px' }}
        >
          <AirdropCardInfoItem
            width="half"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              type="H2"
              alignment="center"
              data-testid="airdrop-minted"
              css={{ marginBottom: '$1' }}
            >
              {minted}
            </Heading>
            <Paragraph alignment="center" size="small">
              {formatMessage({
                id: 'campaignDashboardPage.airdrop.airdropCard.nftsAirdropped',
              })}
            </Paragraph>
          </AirdropCardInfoItem>
        </AirdropCardInfoContainer>

        <AirdropCardInfoContainer marginBottom="medium">
          <Divider orientation="horizontal" />
        </AirdropCardInfoContainer>

        <SnapshotBlockNumber snapshotDate={snapshotDate} blockNumber={blockNumber} />

        <AirdropCardInfoContainer marginBottom="medium">
          <Divider orientation="horizontal" />
        </AirdropCardInfoContainer>

        <AirdropCardInfoContainer>
          <AirdropCardInfoItem
            width="full"
            flexDirection="row"
            alignItems="center"
            justifyContent="spaceBetween"
          >
            <AirdropCardInfoItem
              width="auto"
              flexDirection="column"
              alignItems="start"
              justifyContent="start"
            >
              <Heading type="H5" size="H5" css={{ marginBottom: '$1' }}>
                {formatMessage({
                  id: 'campaignDashboardPage.airdrop.airdropCard.sentOn',
                })}
              </Heading>
              <Paragraph size="small" data-testid="airdrop-sent-on">
                {formatDateToEnUs(new Date(sentOn).toString())}
              </Paragraph>
            </AirdropCardInfoItem>

            <AirdropCardInfoItem
              width="auto"
              flexDirection="column"
              alignItems="start"
              justifyContent="start"
            >
              <Heading type="H5" size="H5" css={{ marginBottom: '$1' }}>
                {formatMessage({
                  id: 'campaignDashboardPage.airdrop.airdropCard.leadtime',
                })}
              </Heading>
              <Paragraph size="small" data-testid="airdrop-lead-time">
                {leadTimeHours < 10 && '0'}
                {`${leadTimeHours}h${leadTimeMinutes}`}
              </Paragraph>
            </AirdropCardInfoItem>
          </AirdropCardInfoItem>
        </AirdropCardInfoContainer>
      </AirdropCardWrapper>
    </Card>
  );
};

export default DoneCard;
