import { Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { AirdropWarning, ContainerWrapper } from './AirdropEmptyState.styles';

const AirdropEmptyState = () => {
  const { formatMessage } = useIntl();
  const { campaignInfo } = useCampaignInfoContext();
  const hasShopifySetup = campaignInfo.shopify?.isExported;
  const hasIpfsCollection = campaignInfo.ipfsLiveCollection?.uploaded;

  return (
    <ContainerWrapper data-testid="airdrop-empty-state" type="fluid">
      {!hasShopifySetup && (
        <AirdropWarning data-testid="airdrop-no-shopify-setup">
          <Paragraph>
            {formatMessage({ id: 'campaignDashboardPage.airdrop.warnings.noShopifySetup' })}
          </Paragraph>
        </AirdropWarning>
      )}
      {!hasIpfsCollection && (
        <AirdropWarning data-testid="airdrop-no-ipfs-collection">
          <Paragraph>
            {formatMessage({ id: 'campaignDashboardPage.airdrop.warnings.noIpfsCollection' })}
          </Paragraph>
        </AirdropWarning>
      )}
    </ContainerWrapper>
  );
};

export default AirdropEmptyState;
