import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import AirdropContent from '../AirdropContent/AirdropContent';
import AirdropEmptyState from '../AirdropEmptyState/AirdropEmptyState';
import AirdropList from '../AirdropList/AirdropList';
import { ContainerWrapper } from './AirdropWrapper.styles';

const AirdropWrapper: React.FC = () => {
  const { campaignInfo } = useCampaignInfoContext();
  const hasShopifySetup = campaignInfo.shopify?.isExported;
  const hasIpfsCollection = campaignInfo.ipfsLiveCollection?.uploaded;
  const hasAirdrop =
    campaignInfo.airdrop?.length > 0 &&
    campaignInfo.airdrop.some((airdrop) => airdrop.status != 'DRAFT');
  const isAirdropAvailable =
    (campaignInfo.lateReveal && hasShopifySetup) || (!campaignInfo.lateReveal && hasIpfsCollection);

  return (
    <ContainerWrapper data-testid="airdrop-wrapper" style={{ width: '100%', height: 'auto' }}>
      {isAirdropAvailable ? (
        <>{hasAirdrop ? <AirdropList /> : <AirdropContent />}</>
      ) : (
        <AirdropEmptyState />
      )}
    </ContainerWrapper>
  );
};

export default AirdropWrapper;
