import { useCallback, useContext } from 'react';
import { ICampaignForm } from '../../../../models/campaignFormModel';
import getApiHost from '../../../../services/host/HostService';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { editCampaignService } from '../../services/editCampaign/editCampaign';

const useSaveCampaign = (): Function => {
  const { campaignInfo, setCampaignInfoRequest, setCampaignInfoSuccess, setCampaignInfoError } =
    useContext(CampaignInfoContext);
  const { BEARERTOKEN } = useEnvContext();
  return useCallback(
    async (campaignInfoData: ICampaignForm = campaignInfo): Promise<boolean> => {
      const { inputErrors, ...campaign } = campaignInfoData;
      try {
        setCampaignInfoRequest();
        await editCampaignService(getApiHost(), BEARERTOKEN, {
          ...campaign,
          status: campaign.status === 'Draft' ? 'Open' : campaign.status,
        });
        setCampaignInfoSuccess({
          ...campaignInfoData,
          inputErrors: {
            brand: '',
            campaign: '',
            externalUrl: '',
            campaignSymbol: '',
            nftBasePrice: '',
            preSaleBasePrice: '',
            gasFeeCap: '',
            airdropAddresses: '',
            airdropNftsHoldersContract: '',
            airdropNftsHoldersSnapshot: '',
          },
        });
        return true;
      } catch (error) {
        setCampaignInfoError(error.response.status);
        return false;
      }
    },
    [campaignInfo, setCampaignInfoRequest, setCampaignInfoError]
  );
};

export { useSaveCampaign };
