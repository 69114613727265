import styled from 'styled-components';

export const ModalWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  transform: translate(-50%, -50%);
  position: fixed;
`;

export const CenteredModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    bottom: 0;
    left: 0;
    transform: translate(0%, 70%);
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 416px;
  height: 255px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  @media (max-width: 768px) {
    width: 100vw;
    height: 273px;
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 0 32px;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding-top: 60px;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 30px;

  @media (max-width: 768px) {
    align-items: flex-start;
    padding-left: 32px;
  }
`;

export const ModalFooter = styled.footer`
  height: auto;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: 425px) {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const ParagaphWrapper = styled.div``;

export const CloseBtn = styled.button`
  cursor: pointer;
  opacity: 0.6;
  width: 32px;
  height: 32px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 16px;
  border: none;
  font-size: 16px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.15);
  align-items: center;
  align-content: center;
  align-self: flex-end;
  justify-content: center;
  &:hover {
    opacity: 1;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
`;
