import { useCallback, useContext } from 'react';
import getApiHost from '../../../../services/host/HostService';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { generateContractErrorMapper } from '../../mappers/generateContractErrorMapper/generateContractErrorMapper';
import { generateContractService } from '../../services/generateContract/generateContract';
import { BlockchainNetwork } from './types';

const useGenerateContract = (id: string, isLiveContract: boolean): (() => Promise<string>) => {
  const { BEARERTOKEN, DEFAULT_NETWORK } = useEnvContext();
  const { campaignInfo, setCampaignInfoSuccess } = useContext(CampaignInfoContext);

  return useCallback(async () => {
    /* istanbul ignore next */
    const defaultNetwork = DEFAULT_NETWORK ?? BlockchainNetwork.GOERLI;

    /* istanbul ignore next */
    const networkToDeploy =
      defaultNetwork === BlockchainNetwork.ETHEREUM_MAINNET && isLiveContract
        ? BlockchainNetwork.ETHEREUM_MAINNET
        : BlockchainNetwork.GOERLI;

    try {
      const response = await generateContractService(
        getApiHost(),
        BEARERTOKEN,
        id,
        isLiveContract,
        networkToDeploy
      );

      if (!response?.data.hash) {
        throw new Error('Request failed. No hash returned!');
      }

      if (isLiveContract) {
        campaignInfo.liveContract = {
          blockChain: networkToDeploy,
          hash: response?.data.hash,
          publishedDate: response?.data.publishedDate,
          isPublishing: false,
        };
      } else {
        campaignInfo.testNetContract = {
          blockChain: networkToDeploy,
          hash: response?.data.hash,
          publishedDate: response?.data.publishedDate,
          isPublishing: false,
        };
      }

      setCampaignInfoSuccess(campaignInfo);

      return '';
    } catch (error) {
      setCampaignInfoSuccess(campaignInfo);
      return generateContractErrorMapper(error?.response?.data?.name ?? error);
    }
  }, [campaignInfo, setCampaignInfoSuccess, getApiHost(), BEARERTOKEN]);
};

export { useGenerateContract };
