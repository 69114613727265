import axios from 'axios';

const compressCollection = async (
  baseUrl: string,
  bearerToken: string,
  id: string,
  reveal: boolean
): Promise<void> => {
  return axios.post(
    `${baseUrl}azure/storage/campaign/${id}/collection/compress?reveal=${reveal}`,
    {},
    {
      headers: { authorization: bearerToken },
    }
  );
};
export { compressCollection };
