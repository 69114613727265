import { useCallback, useContext } from 'react';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';

import getApiHost from '../../../../services/host/HostService';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import useAirdrop from '../../components/AirdropContent/hooks/useAirdrop/useAirdrop';
import { getHoldersService, IGetHoldersProps } from '../../services/getHolders/getHolders';

export interface IGetHoldersResponse {
  success: boolean;
  errors?: {
    status: number;
    message: { name: string };
  };
}

type Hook = () => (contractAddress: string) => Promise<IGetHoldersResponse>;

const useGetHolders: Hook = () => {
  const { campaignInfo, setCampaignInfoSuccess, setCampaignInfoError } =
    useContext(CampaignInfoContext);
  const { BEARERTOKEN } = useEnvContext();
  const { getAirdropDraft } = useAirdrop();

  return useCallback(
    async (contractAddress: string) => {
      try {
        const getHoldersProps: IGetHoldersProps = {
          campaignId: campaignInfo.id,
          vendor: campaignInfo.brand,
          title: campaignInfo.campaign,
          date: getAirdropDraft().snapshotDate,
        };

        const response = await getHoldersService(
          getApiHost(),
          BEARERTOKEN,
          contractAddress,
          getHoldersProps
        );

        const editedAirdropList = campaignInfo.airdrop.map((airdrop) => {
          if (airdrop.status === 'DRAFT') {
            airdrop.blockNumber = response.data?.blockNumber;
            airdrop.nftsHolders = response.data?.holders;
            airdrop.nftsAvailable = response.data?.campaignProductsInventory;
          }
          return airdrop;
        });

        setCampaignInfoSuccess({ ...campaignInfo, airdrop: editedAirdropList });

        return {
          success: true,
        };
      } catch (error: any) {
        setCampaignInfoError(error.response.status);
        return {
          success: false,
          errors: {
            status: error.response?.status,
            message: error.response?.data,
          },
        };
      }
    },
    [BEARERTOKEN, campaignInfo, setCampaignInfoSuccess, setCampaignInfoError]
  );
};

export { useGetHolders };
