import { styled } from '@bees/vision-tokens';
import { Button } from '@hexa-ui/components';

export const CustomDialogButtonActionsWrapper = styled('div', {
  display: 'flex',
  gap: '$2',
  justifyContent: 'flex-end',

  variants: {
    reverseButtons: {
      true: {
        justifyContent: 'flex-start',
        flexDirection: 'row-reverse',
      },
    },
  },
});

export const CustomDialogConfirmButton = styled(Button, {
  variants: {
    actionType: {
      regular: {
        background: '$neutral100',
      },
      delete: {
        background: '$semanticErrorText !important',
      },
    },
  },
});
