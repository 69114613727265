import { Grid } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import ReviewCard from '../../../../shared-components/CampaignFormAggregator/components/ReviewForm/ReviewCard/ReviewCard';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { config } from './config';
const { Item } = Grid;

const Summary = (): JSX.Element => {
  const { campaignInfo } = useCampaignInfoContext();
  const { collectionProps, airdropProps } = config(campaignInfo);
  const { formatMessage } = useIntl();

  return (
    <>
      <Item lg={6} data-testid="summary-content">
        <ReviewCard
          props={collectionProps}
          cardTitle={formatMessage({
            id: 'campaignDashboardPage.airdrop.summary.airdropCollection.title',
          })}
        />
      </Item>
      <Item lg={6}>
        <ReviewCard
          props={airdropProps}
          cardTitle={formatMessage({
            id: 'campaignDashboardPage.airdrop.summary.airdrop.title',
          })}
        />
      </Item>
    </>
  );
};

export default Summary;
