import { Card, Heading, ProgressMeter } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import {
  AirdropCardInfoContainer,
  AirdropCardInfoItem,
  AirdropCardWrapper,
} from '../../AirdropCard.styles';
import { SnapshotBlockNumber } from '../../SnapshotBlockNumber/SnapshotBlockNumber';

type OnGoingCardProps = {
  progress: number;
  total: number;
  snapshotDate: string;
  blockNumber: number;
};

const OnGoingCard: React.FC<OnGoingCardProps> = ({
  total,
  progress,
  snapshotDate,
  blockNumber,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Card
      data-testid="airdrop-ongoing-card"
      border="medium"
      elevated="medium"
      css={{ height: '280px' }}
    >
      <AirdropCardWrapper>
        <AirdropCardInfoContainer
          flexDirection="row"
          alignItems="center"
          justifyContent={'center'}
          marginBottom="small"
          css={{ minHeight: '70px' }}
        >
          <AirdropCardInfoItem
            width="half"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              type="H2"
              alignment="center"
              data-testid="airdrop-progress"
              css={{ marginBottom: '$1' }}
            >
              {progress}/{total}
            </Heading>
          </AirdropCardInfoItem>
        </AirdropCardInfoContainer>

        <AirdropCardInfoContainer marginBottom="medium">
          <AirdropCardInfoItem
            width="full"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <ProgressMeter
              label={formatMessage({
                id: 'campaignDashboardPage.airdrop.airdropCard.airdropProgress',
              })}
              value={progress}
              maxValue={total}
              dataTestId="airdrop-progress-meter"
            />
          </AirdropCardInfoItem>
        </AirdropCardInfoContainer>

        <SnapshotBlockNumber snapshotDate={snapshotDate} blockNumber={blockNumber} />
      </AirdropCardWrapper>
    </Card>
  );
};

export default OnGoingCard;
