import { useCallback, useContext } from 'react';

import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';

const useDownloadAllowlist = (): Function => {
  const { campaignInfo } = useContext(CampaignInfoContext);

  return useCallback(
    async (isMinters: boolean): Promise<File> => {
      try {
        if (isMinters && !campaignInfo.minting.allowList.length) {
          throw new Error('Minters allowlist does not exist!');
        }

        if (!isMinters && !campaignInfo.preSale.allowList.length) {
          throw new Error('Presale allowlist does not exist!');
        }

        const allowList = isMinters
          ? campaignInfo.minting.allowList
          : campaignInfo.preSale.allowList;

        const walletAddresses = allowList.map((item) => {
          return item.walletAddress;
        });

        const csvContent = `Wallet\n${walletAddresses.join('\n')}`;

        const csvName = `${campaignInfo.id}-${isMinters ? 'minters' : 'preSale'}-allowlist`;

        return new File([csvContent], csvName, { type: 'text/csv' });
      } catch (error) {
        throw new Error(error.message);
      }
    },
    [campaignInfo]
  );
};

export { useDownloadAllowlist };
