const generateContractErrorMapper = (errorName: string): string => {
  switch (errorName) {
    case 'Live contract already published':
      return 'campaignDashboardPage.contentCard.publishContract.errors.liveContractAlreadyPublished';

    case 'This campaign is already deploying a contract':
      return 'campaignDashboardPage.contentCard.publishContract.errors.alreadyDeployingContract';

    default:
      return 'campaignDashboardPage.contentCard.publishContract.errors.contractDeployFailed';
  }
};

export { generateContractErrorMapper };
