import { Card, Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const { Container, Item } = Grid;

export const AirdropCard = styled(Card, {
  width: '100%',
  height: '100%',
});

export const AirdropContainer = styled(Container, {
  display: 'flex',

  variants: {
    background: {
      gray: {
        background: '$neutral20',
      },
      none: {
        background: 'none',
      },
    },
    flexDirection: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
    },
    justifyContent: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      spaceBetween: {
        justifyContent: 'space-between',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    alignItems: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
  },
});

export const AirdropItem = styled(Item, {
  variants: {
    background: {
      white: {
        background: '$neutral0',
      },
    },
  },
});
