import axios from 'axios';

const uploadToIpfsService = async (
  baseUrl: string,
  bearerToken: string,
  id: string,
  lateReveal?: boolean
): Promise<any> => {
  return axios.post(
    `${baseUrl}campaign/${id}/ipfs/upload?reveal=${lateReveal}`,
    {},
    {
      headers: { authorization: bearerToken },
    }
  );
};

export { uploadToIpfsService };
