import { BlobItem } from '@azure/storage-blob';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDeleteCollection } from '../useDeleteCollection/useDeleteCollection';
import { useGetSasToken } from '../useGetSasToken/useGetSasToken';
import { StorageOperations } from '../useGetSasToken/useGetSasToken.types';
import { useListCollection } from '../useListCollection/useListCollection';
import { AzureStorage, useUploadCollection } from '../useUploadCollection/useUploadCollection';

interface IHookProps {
  files: File[];
  azureStorage: AzureStorage;
  abortControllerRef: MutableRefObject<AbortController>;
  campaignId: string;
  setIsUploading: Dispatch<SetStateAction<boolean>>;
  setUploadProgress: Dispatch<SetStateAction<number>>;
  setShouldEdit: Dispatch<SetStateAction<boolean>>;
}
type Hook = () => ({
  files,
  azureStorage,
  abortControllerRef,
  campaignId,
  setIsUploading,
  setUploadProgress,
  setShouldEdit,
}: IHookProps) => Promise<void>;

const useAzureManagement: Hook = () => {
  const getSasToken = useGetSasToken();
  const listCollection = useListCollection();
  const deleteCollection = useDeleteCollection();
  const uploadCollection = useUploadCollection();
  const toastService = useToast();
  const { formatMessage } = useIntl();

  return useCallback(
    async ({
      files,
      azureStorage,
      abortControllerRef,
      campaignId,
      setIsUploading,
      setUploadProgress,
      setShouldEdit,
    }: IHookProps) => {
      try {
        setIsUploading(true);

        const listSasToken: string = await getSasToken(StorageOperations.LIST);
        const collectionItems: AsyncIterableIterator<BlobItem> = await listCollection(
          listSasToken,
          `${campaignId}/${azureStorage}/`
        );
        const compressedCollectionItems: AsyncIterableIterator<BlobItem> = await listCollection(
          listSasToken,
          `${campaignId}/compressed-${campaignId}-${azureStorage}.zip`
        );

        for await (const blobItem of collectionItems) {
          const deleteSasToken: string = await getSasToken(StorageOperations.DELETE);
          await deleteCollection(deleteSasToken, blobItem);
        }

        for await (const compressedBlobItem of compressedCollectionItems) {
          const deleteCompressedCollectionSasToken: string = await getSasToken(
            StorageOperations.DELETE
          );
          await deleteCollection(deleteCompressedCollectionSasToken, compressedBlobItem);
        }

        const uploadSasToken: string = await getSasToken(
          StorageOperations.UPLOAD,
          abortControllerRef.current.signal
        );
        await uploadCollection(
          files,
          uploadSasToken,
          setUploadProgress,
          abortControllerRef.current.signal,
          azureStorage
        );

        setShouldEdit(true);
        abortControllerRef.current = new AbortController();
      } catch (error) {
        /* istanbul ignore next */
        if (abortControllerRef.current.signal.aborted) {
          toastService.notify({
            type: TypeToast.WARNING,
            message: formatMessage({
              id: 'campaignFormAggregator.collectionForm.toast.cancelledMessage',
            }),
          });
        } else {
          error.message === 'Invalid JSON file name!'
            ? toastService.notify({
                type: TypeToast.ERROR,
                message: formatMessage({
                  id: 'campaignFormAggregator.collectionForm.toast.invalidJsonFileName',
                }),
              })
            : toastService.notify({
                type: TypeToast.ERROR,
                message: formatMessage({
                  id: 'campaignFormAggregator.collectionForm.toast.uploadFailed',
                }),
              });
        }

        setIsUploading(false);
        setUploadProgress(0);
        abortControllerRef.current = new AbortController();
        const listSasToken: string = await getSasToken(StorageOperations.LIST);
        const collectionItems: AsyncIterableIterator<BlobItem> = await listCollection(
          listSasToken,
          `${campaignId}/${azureStorage}/`
        );

        for await (const blobItem of collectionItems) {
          const deleteSasToken: string = await getSasToken(StorageOperations.DELETE);
          await deleteCollection(deleteSasToken, blobItem);
        }
        setShouldEdit(true);
      }
    },
    [getSasToken, listCollection, deleteCollection, uploadCollection, toastService]
  );
};

export { useAzureManagement };
