import { LoadingBuzz } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useGetCampaign } from '../../../../shared-components/CampaignFormAggregator/hooks/useGetCampaign/useGetCampaign';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import AirdropCard from '../AirdropCard/AirdropCard';
import { AirdropListContainer, AirdropLoadingContainer } from './AirdropList.styles';

const AirdropList = () => {
  const { campaignInfo } = useCampaignInfoContext();
  const getCampaign = useGetCampaign(campaignInfo.id);
  const [isDeleting, setIsDeleting] = useState(false);

  const hasOngoingAirdrops = (): boolean => {
    return campaignInfo.airdrop.some((airdrop) => airdrop.status === 'ONGOING');
  };

  useEffect(() => {
    if (hasOngoingAirdrops()) {
      const fetchForAirdropUpdates = setInterval(() => {
        getCampaign();
      }, 10000);

      return () => clearInterval(fetchForAirdropUpdates);
    }
  }, [campaignInfo.airdrop]);

  return (
    <AirdropListContainer data-testid="airdrop-list" type="fluid">
      {isDeleting && (
        <AirdropLoadingContainer>
          <LoadingBuzz size="medium" dataTestId="loading-buzz" />
        </AirdropLoadingContainer>
      )}

      {!isDeleting && (
        <>
          {campaignInfo.airdrop.map((airdrop) => (
            <AirdropCard
              id={airdrop.id}
              key={airdrop.id}
              status={airdrop.status}
              nftsAvailable={airdrop.nftsAvailable}
              nftsHolders={airdrop.nftsHolders.length}
              snapshotDate={airdrop.snapshotDate}
              blockNumber={airdrop.blockNumber}
              minted={airdrop.minted}
              sentOn={airdrop.startedAt}
              leadTime={airdrop.endedAt - airdrop.startedAt}
              setIsDeleting={setIsDeleting}
            />
          ))}
        </>
      )}
    </AirdropListContainer>
  );
};

export default AirdropList;
