import { Button } from '@admin-portal-shared-components/button';
import { Divider } from '@admin-portal-shared-components/divider';
import { ProgressMeter } from '@admin-portal-shared-components/progress-meter';
import { Alert, Paragraph } from '@hexa-ui/components';
import { Grid } from '@material-ui/core';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FolderUploadIcon } from '../../../../shared-components/icons/FolderUploadIcon';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { formatDateToEnUs } from '../../../../utils/formats/FormattedDate/formatted-date';
import { Modal } from '../../../Modal/Modal';
import { useAzureDownloadManagement } from '../../hooks/useAzureDownloadManagement/useAzureDownloadManagement';
import { useAzureManagement } from '../../hooks/useAzureManagement/useAzureManagement';
import { useNftQuantityValidator } from '../../hooks/useNftQuantityValidator/useNftQuantityValidator';
import { useSaveCampaign } from '../../hooks/useSaveCampaign/useSaveCampaign';
import { AzureStorage } from '../../hooks/useUploadCollection/useUploadCollection';
import CancelUploadDialog from '../CancelUploadDialog/CancelUploadDialog';
import { RevealToggle } from '../RevealToggle/RevealToggle';
import { CollectionWrapper } from './CollectionForm.styles';

type CollectionFormProps = {
  setStep: (step: number) => void;
  goToStep: number;
  setGoToStep: (step: number) => void;
  abortControllerRef?: MutableRefObject<AbortController>;
};

const CollectionForm: React.FC<CollectionFormProps> = ({
  setStep,
  goToStep,
  setGoToStep,
  abortControllerRef,
}): JSX.Element => {
  const { campaignInfo, isUploading, setIsUploading, setCampaignInfoSuccess } =
    useCampaignInfoContext();
  const [uploadProgress, setUploadProgress] = useState(0);
  const azureDownload = useAzureDownloadManagement();
  const editCampaign = useSaveCampaign();
  const toastService = useToast();
  const [isCancelUploadDialogOpen, setIsCancelUploadDialogOpen] = useState(false);
  const [isCancelRevealDialogOpen, setIsCancelRevealDialogOpen] = useState(false);
  const [isToggleDisabledByModal, setIsToggleDisabledByModal] = useState(false);
  const { formatMessage } = useIntl();
  const [shouldEdit, setShouldEdit] = useState(false);
  const nftQuantityValidator = useNftQuantityValidator();
  const [isDownloading, setIsDownloading] = useState(false);

  const shouldDisplayDropzone = (): boolean => {
    if (
      (campaignInfo?.liveContract?.hash && !campaignInfo.lateReveal) ||
      campaignInfo?.ipfsLiveCollection?.uploaded
    ) {
      return false;
    }

    return true;
  };

  const azureManagement = useAzureManagement();

  /* istanbul ignore next */
  useEffect(() => {
    if (shouldEdit) {
      editCampaign();
      setIsUploading(false);
      setUploadProgress(0);
      setShouldEdit(false);
    }
  }, [shouldEdit]);

  const handleUploadCollection = useCallback(
    async (files: File[]) => {
      if (nftQuantityValidator(files.length)) {
        await azureManagement({
          files,
          azureStorage: AzureStorage.collection,
          abortControllerRef,
          campaignId: campaignInfo.id,
          setIsUploading,
          setUploadProgress,
          setShouldEdit,
        });
      } else {
        toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({
            id: 'campaignFormAggregator.collectionForm.toast.invalidFilesNumber',
          }),
        });
      }
    },
    [campaignInfo]
  );

  const handleDownloadCollection = useCallback(async () => {
    await azureDownload({
      azureStorage: AzureStorage.collection,
      campaignId: campaignInfo.id,
      reveal: false,
      setIsDownloading,
    });
  }, [campaignInfo]);

  const handleCancelUploadDialogOpen = useCallback(() => {
    setIsCancelUploadDialogOpen(!isCancelUploadDialogOpen);
  }, [isCancelUploadDialogOpen]);

  const handleLateReveal = useCallback(async () => {
    setCampaignInfoSuccess({ ...campaignInfo, lateReveal: !campaignInfo?.lateReveal });
    setIsToggleDisabledByModal(true);
  }, [campaignInfo]);

  return (
    <>
      {!campaignInfo.lateReveal && (
        <Alert
          message="Reveal cannot be reversed."
          type="warning"
          css={{ marginBottom: '$4', width: '360px' }}
        />
      )}
      <RevealToggle
        isDisabled={
          !!campaignInfo?.liveContract?.hash || isToggleDisabledByModal || campaignInfo.lateReveal
        }
        isChecked={campaignInfo.lateReveal}
        handleToggle={() => {
          setIsCancelRevealDialogOpen(!isCancelRevealDialogOpen);
        }}
        collection={campaignInfo.placeholderCollectionStorage}
      />
      {isCancelRevealDialogOpen && (
        <Modal
          data-testid="collectionForm-modal"
          setIsOpen={setIsCancelRevealDialogOpen}
          buttonFunction={handleLateReveal}
        />
      )}
      <Grid container direction="column" data-testid="collectionForm">
        <Grid item sm={10} md={8} lg={5} xl={4}>
          <Paragraph size="small" weight="medium" css={{ marginBottom: '$4' }}>
            {formatMessage({ id: 'campaignFormAggregator.collectionForm.title' })}
          </Paragraph>
          {!isUploading && (
            <CollectionWrapper isVisible={shouldDisplayDropzone()} data-testid="dropzoneWrapper">
              <DropzoneArea
                onDrop={(files) => handleUploadCollection(files)}
                dropzoneText={formatMessage({
                  id: 'campaignFormAggregator.collectionForm.description',
                })}
                acceptedFiles={['application/json']}
                showAlerts={false}
                showPreviewsInDropzone={false}
                filesLimit={100000}
                maxFileSize={100000000}
                inputProps={{ id: 'fileUploader' }}
                dropzoneClass="collectionDropzone"
                disableRejectionFeedback={true}
                //@ts-ignore
                Icon={FolderUploadIcon}
              />
              <Button
                variant="secondary"
                style={{
                  position: 'relative',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  bottom: '85px',
                }}
                data-testid="dropzoneButton"
              >
                {campaignInfo.liveCollectionStorage?.uploaded
                  ? formatMessage({ id: 'campaignFormAggregator.collectionForm.buttons.replace' })
                  : formatMessage({
                      id: 'campaignFormAggregator.collectionForm.buttons.browseFiles',
                    })}
              </Button>
            </CollectionWrapper>
          )}

          <CollectionWrapper
            isVisible={isUploading}
            style={{
              height: '320px',
              border: '1px solid #000',
              borderRadius: '16px',
            }}
            data-testid="uploadCard"
          >
            <Grid item>
              <Paragraph>
                {formatMessage({ id: 'campaignFormAggregator.collectionForm.upload.title' })}
              </Paragraph>
            </Grid>
            <Grid item>
              <ProgressMeter maxValue={101} value={uploadProgress} css={{ width: '160px' }} />
            </Grid>
            <Grid item>
              <Paragraph css={{ marginBottom: '$4' }}>
                {formatMessage({ id: 'campaignFormAggregator.collectionForm.upload.description' })}
              </Paragraph>
            </Grid>
            <Button
              onClick={handleCancelUploadDialogOpen}
              variant="secondary"
              data-testid="cancelButton"
            >
              {formatMessage({ id: 'campaignFormAggregator.collectionForm.upload.cancelButton' })}
            </Button>
          </CollectionWrapper>
        </Grid>
        {!isUploading && campaignInfo.liveCollectionStorage?.uploaded && (
          <Grid item sm={10} md={8} lg={5} xl={4} data-testid="uploadedFileData">
            <Divider />
            <Paragraph css={{ marginTop: '$4' }}>
              {formatMessage({ id: 'campaignFormAggregator.collectionForm.totalFiles' })}:{' '}
              {campaignInfo.liveCollectionStorage?.filesCount}
            </Paragraph>
            <Paragraph css={{ marginBottom: '$4' }}>
              {formatMessage({ id: 'campaignFormAggregator.collectionForm.lastUploaded' })}:{' '}
              {formatDateToEnUs(campaignInfo.liveCollectionStorage?.uploadDate)}
            </Paragraph>
            <Button
              variant="secondary"
              leading
              data-testid="collectionDownloadButton"
              onClick={handleDownloadCollection}
              isLoading={isDownloading}
              disabled={isDownloading}
            >
              {formatMessage({ id: 'campaignFormAggregator.collectionForm.buttons.download' })}
            </Button>
          </Grid>
        )}
      </Grid>
      <CancelUploadDialog
        isOpen={isCancelUploadDialogOpen}
        setIsOpen={handleCancelUploadDialogOpen}
        goToStep={goToStep}
        setGoToStep={setGoToStep}
        setStep={setStep}
        title="campaignFormAggregator.collectionForm.cancelUploadDialog.cancelUploadTitle"
        description="campaignFormAggregator.collectionForm.cancelUploadDialog.cancelUploadDescription"
        leftButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.cancelButton"
        rightButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.keepUploadButton"
        abortControllerRef={abortControllerRef}
      />
    </>
  );
};

export default CollectionForm;
