import { Heading, Paragraph } from '@hexa-ui/components';

import {
  Checkmark,
  ContainerWrapper,
  ContentWrapper,
  Header,
  Position,
} from './CardContainer.styles';
import { CardContainerProps, CardContainerStatus } from './types';

const CardContainer: React.FC<CardContainerProps> = ({
  position,
  title,
  description,
  status,
  children,
}) => {
  const isDisabled = status === CardContainerStatus.DISABLED;

  return (
    <ContainerWrapper data-testid="card-container">
      <Header className={isDisabled ? 'disabled' : ''}>
        <Position className={isDisabled ? 'disabled' : ''} data-testid="container-position">
          {status === CardContainerStatus.COMPLETED && <Checkmark />}
          {status === CardContainerStatus.ENABLED && <Heading size="H1">{position}</Heading>}
          {status === CardContainerStatus.DISABLED && <Heading size="H1">{position}</Heading>}
        </Position>
        <Heading size="H2">{title}</Heading>
        <Paragraph>{description}</Paragraph>
      </Header>
      <ContentWrapper>{children}</ContentWrapper>
    </ContainerWrapper>
  );
};

export default CardContainer;
