import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import CampaignDashboardPage from './pages/CampaignDashboardPage/CampaignDashboardPage';
import CampaignInfoContextProvider from './utils/context/campaignInfoContext/campaignInfo.context';
import CampaignListContextProvider from './utils/context/campaignListContext/campaignList.context';

const HomePage = lazy(() => import('./pages/Home/HomePage'));
const NewCampaignPage = lazy(() => import('./pages/NewCampaignPage/NewCampaignPage'));

const Router = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path="/nftcore"
        element={
          <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
            <CampaignListContextProvider>
              <HomePage />
            </CampaignListContextProvider>
          </Suspense>
        }
      />
      <Route
        path="/nftcore/campaign"
        element={
          <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
            <CampaignInfoContextProvider>
              <NewCampaignPage />
            </CampaignInfoContextProvider>
          </Suspense>
        }
      />
      <Route
        path="/nftcore/campaign/:id"
        element={
          <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
            <CampaignInfoContextProvider>
              <NewCampaignPage />
            </CampaignInfoContextProvider>
          </Suspense>
        }
      />
      <Route
        path="/nftcore/campaign/:id/dashboard/:campaign"
        element={
          <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
            <CampaignInfoContextProvider>
              <CampaignDashboardPage />
            </CampaignInfoContextProvider>
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/nftcore" replace />} />
    </Routes>
  );
};
export default Router;
