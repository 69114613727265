import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useCampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { config } from './config';
import ReviewCard from './ReviewCard/ReviewCard';

interface ReviewFormProps {
  setStep: (step: number) => void;
}

const ReviewForm = ({ setStep }: ReviewFormProps): JSX.Element => {
  const { campaignInfo } = useCampaignInfoContext();
  const { campaignProps, collectionProps, mintingProps } = config(campaignInfo);
  const { formatMessage } = useIntl();

  return (
    <Grid container direction="row" spacing={3} data-testid="reviewForm">
      <Grid item xs={7}>
        <ReviewCard
          props={campaignProps}
          step={0}
          setStep={setStep}
          cardTitle={formatMessage({ id: 'campaignFormAggregator.reviewForm.campaignCard.title' })}
          edit={true}
        />
      </Grid>
      <Grid item xs={5}>
        <Grid container direction="column">
          <ReviewCard
            props={collectionProps}
            step={1}
            setStep={setStep}
            cardTitle={formatMessage({
              id: 'campaignFormAggregator.reviewForm.collectionCard.title',
            })}
            edit={true}
          />
          <ReviewCard
            props={mintingProps}
            step={2}
            setStep={setStep}
            cardTitle={formatMessage({ id: 'campaignFormAggregator.reviewForm.mintingCard.title' })}
            edit={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewForm;
