import { Button, Card, Heading, Paragraph } from '@hexa-ui/components';
import { Wallet } from '@hexa-ui/icons';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useCampaignInfoContext } from '../../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { useEnvContext } from '../../../../../utils/hooks/EnvProvider/EnvProvider';
import { CardContainerStatus } from '../../CardContainer/types';
import { CardWrapper } from '../ContentCard.styles';
import { ClaimingProps } from '../types';

const Claiming = ({ status }: ClaimingProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { campaignInfo } = useCampaignInfoContext();
  const isDisabled = status === CardContainerStatus.DISABLED;
  const { CLAIM_APP_BASE_URL } = useEnvContext();

  const generateClaimUrl = useMemo((): string => {
    const allowedBrands = ['Budweiser', 'Bud Light'];
    if (allowedBrands.includes(campaignInfo.brand)) {
      const brandName = campaignInfo.brand
        .split('')
        .filter((e) => e.trim().length)
        .join('')
        .toLocaleLowerCase();
      return `${CLAIM_APP_BASE_URL}/${brandName}/previewOnly`;
    }
    return `${CLAIM_APP_BASE_URL}/budweiser/previewOnly`;
  }, [campaignInfo, CLAIM_APP_BASE_URL]);

  const openClaimPage = useCallback(() => {
    window.open(generateClaimUrl, '_blank');
  }, [generateClaimUrl, CLAIM_APP_BASE_URL]);

  return (
    <CardWrapper>
      <Card
        border="medium"
        elevated="small"
        data-testid="claiming-card"
        className={isDisabled ? 'card disabled' : 'card'}
      >
        <Wallet size="xlarge" className="icon" />
        <Heading size="H3">
          {formatMessage({
            id: 'campaignDashboardPage.contentCard.claiming.header',
          })}
        </Heading>
        <Paragraph>
          {formatMessage({
            id: 'campaignDashboardPage.contentCard.claiming.description',
          })}
        </Paragraph>
        <Button
          variant="secondary"
          size="medium"
          data-testid="show-button"
          disabled={isDisabled}
          onClick={openClaimPage}
        >
          {formatMessage({
            id: 'campaignDashboardPage.contentCard.claiming.button',
          })}
        </Button>
      </Card>
    </CardWrapper>
  );
};

export default Claiming;
