import { useCallback, useContext } from 'react';

import { ICampaignForm } from '../../../../models/campaignFormModel';
import { CampaignInfoContext } from '../../../../utils/context/campaignInfoContext/campaignInfo.context';
import { Steps } from '../../CampaignFormAggregator.types';

const useValidateForm = (state: ICampaignForm, step: number): VoidFunction => {
  const { campaignInfo, setIsCampaignFormValid } = useContext(CampaignInfoContext);

  return useCallback(async () => {
    const isFormValid = validateForm(state, step);
    setIsCampaignFormValid(isFormValid);
  }, [campaignInfo]);
};

const validateForm = (state: ICampaignForm, step: number): boolean => {
  if (!state.brand || !state.campaign || !state.campaignSymbol || !state.nftBasePrice) return false;

  if (Number(state.nftBasePrice).toFixed(2) === '0.00' || isNaN(Number(state.nftBasePrice)))
    return false;

  if (
    state.preSale.enable &&
    (Number(state.preSale.basePrice).toFixed(2) === '0.00' ||
      isNaN(Number(state.preSale.basePrice)))
  )
    return false;

  if (state.preSale.enable && (!state.preSale.startDate || !state.preSale.endDate)) return false;

  if (
    state.launchDate === 'Invalid Date' ||
    state.preSale.startDate === 'Invalid Date' ||
    state.preSale.endDate === 'Invalid Date'
  )
    return false;

  const today = new Date(new Date().toDateString());

  if (state.launchDate && new Date(state.launchDate) < today) return false;

  if (
    state.preSale.enable &&
    state.launchDate &&
    new Date(state.launchDate) <= new Date(state.preSale.endDate)
  )
    return false;

  if (
    state.preSale.enable &&
    (new Date(state.preSale.startDate) < today || new Date(state.preSale.endDate) < today)
  )
    return false;

  if (state.preSale.enable && new Date(state.preSale.endDate) <= new Date(state.preSale.startDate))
    return false;

  if (
    !state.minting.smartGas &&
    (state.minting.gasFeeCap.toFixed(2) === '0.00' || isNaN(state.minting.gasFeeCap)) &&
    step === Steps.MINTING_STEP
  )
    return false;

  return true;
};

export { useValidateForm };
